import NoResults from "../../../components/shared/NoResults";
import Wrapper from "../../../components/shared/Wrapper";
import BackToolnav from "../../../components/toolnav/BackToolnav";

export default function LoadingClaim() {
  return (
    <>
      <BackToolnav />
      <Wrapper
        title={`Name`}
        subtitle={`Loading`}
        description={
          <>
            <span
              style={{
                padding: 4,
                borderWidth: 1,
                marginRight: 4,
              }}
            >
              Loading
            </span>
            Created : Loading
          </>
        }
        actions={<></>}
      >
        <div>
          <div className="flex flex-row bg-whitish overflow-auto">
            <div className="grid grid-cols-12 gap-0 bg-gray-50 w-full">
              <div className="col-span-12 sm:col-span-9">
                <div className="flex justify-start ml-10">
                  <div className="book">
                    <div className="page">
                      <div className="subpage"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-3 bg-white border-l px-6">
                <div className=" border-b py-4">Attachments</div>
                <div className="">
                  <div className="mt-12">
                    <NoResults title={"Loading ....."} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}
