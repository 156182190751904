import { Button, Modal, Form, Row, Col, Select, message } from "antd";
import { useEffect, useState } from "react";
import patchDataClaim from "../../../../services/patchDataClaim";

const { Option } = Select;

export default function UpdateCountry({
  isVisible,
  selected,
  setVisible,
  refetch,
}: any) {
  const [isLoading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    const values = await form.getFieldsValue();

    const { country } = values;
    if (!country) {
      message.error("Please select country");
      return;
    }

    const data = { country };
    const uri = `claim/claimers/${selected?._id}/update/country`;
    const response = await patchDataClaim(data, uri);
    if (response.success) {
      message.success("Updated Successfully");
      await refetch();
      form.resetFields();
      hideModal();
    } else {
      message.error(response.message);
    }
  };

  const hideModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [selected]);

  return (
    <Modal
      open={isVisible}
      title="Update Country"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="udateCountry"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="udateCountry"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="mb-4">
          You are updating
          <span className="text-green-700 font-bold mx-1">
            {selected?.name}
          </span>
          country
        </div>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="country"
              label="Country"
              initialValue={selected?.country}
              rules={[
                {
                  required: true,
                  message: "Please select country",
                },
              ]}
            >
              <Select
                defaultValue={selected?.country}
                placeholder="Country"
              >
                <Option value={"Tanzania"}>Tanzania</Option>
                <Option value={"Kenya"}>Kenya</Option>
                <Option value={"Uganda"}>Uganda</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
