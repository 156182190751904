import { useContext, useEffect, useState } from "react";
import HomeToolnav from "../../components/toolnav/HomeToolnav";
import { AuthContext } from "../../contexts/AuthContext";
import fetchDataClaim from "../../services/fetchDataClaim";
import fetchDataTrace from "../../services/fetchDataTrace";
import CategWidget from "./components/CategWidget";
import CountWidget from "./components/CountWidget";
import MainChart from "./components/MainChart";
import MonthlyWidget from "./components/MonthlyWidget";
import MonthWidget from "./components/MonthWidget";
import YTDWidget from "./components/YTDWidget";

export default function Dashboard() {
  const { user } = useContext(AuthContext);
  const year = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(year);
  const [selectedUser, setSelectedUser] = useState(user);
  const [constants, setConstants] = useState<any>([]);
  const [claims, setClaims] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const records = [{ year: year - 2 }, { year: year - 1 }, { year: year }];

  const handleFetchContants = async () => {
    const uri = `claim/constants/all`;
    const response = await fetchDataClaim({ uri });
    setConstants(response?.payload);
  };

  const handleFetchUsers = async () => {
    const response = await fetchDataTrace({ uri: "users/claimers" });
    if (response?.success) {
      if (
        user?.email === "akher@sfl.co.tz" ||
        user?.email === "farishta.kohli@karimjee.com"
      ) {
        const filter = response?.payload.filter(
          (claimer: any) =>
            claimer?.email?.substr(claimer?.email?.length - 2).toString() !==
            "tz"
        );
        setUsers(filter);
        return;
      }
      setUsers(response?.payload);
    }
  };

  const handleChangeStaff = async (user: any) => {
    setSelectedUser(user);
    setLoading(true);
    const all = user?.firstname === "All" ? "" : user?.email;
    const uri = `claim/reports/yearly/${selectedYear}?staff=${all}`;
    const response = await fetchDataClaim({ uri });
    if (response.success) {
      setClaims(response?.payload);
    }
    setLoading(false);
  };

  const handleFetchYearlyData = async () => {
    setLoading(true);
    const all = selectedUser?.firstname === "All" ? "" : selectedUser?.email;
    const uri = `claim/reports/yearly/${selectedYear}?staff=${all}`;
    const response = await fetchDataClaim({ uri });
    if (response.success) {
      setClaims(response?.payload);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleFetchYearlyData();
  }, [selectedYear]);

  useEffect(() => {
    handleFetchYearlyData();
    handleFetchContants();
    handleFetchUsers();
  }, []);

  const y = records.map((record: any, i: any) => (
    <button
      className={
        selectedYear === record.year
          ? "rounded-sm border border-gray-200 p-2 focus:outline-none text-xs hover:bg-gray-200 ml-2 bg-gray-200"
          : "rounded-sm border border-gray-200 p-2 focus:outline-none text-xs hover:bg-gray-200 ml-2"
      }
      onClick={() => setSelectedYear(record?.year)}
    >
      <div className="flex justify-between items-center text-gray-800">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-calendar"
        >
          <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
          <path d="M16 2v4M8 2v4M3 10h18" />
        </svg>
        <span className="ml-2">{record.year}</span>
      </div>
    </button>
  ));
  return (
    <>
      <HomeToolnav
        link={{ name: "Dashboard", to: "/dashboard" }}
        handleChangeStaff={handleChangeStaff}
        users={users}
        user={user}
      />
      <div className="grid grid-cols-12 gap-x-2 m-4">
        <div className="col-span-12 sm:col-span-9">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 sm:col-span-9 border h-32 rounded-md bg-white">
              <MonthWidget
                isLoading={isLoading}
                claims={claims}
                year={selectedYear}
              />
            </div>
            <div className="col-span-12 sm:col-span-3 border h-32 rounded-md bg-white">
              <YTDWidget
                isLoading={isLoading}
                claims={claims}
                year={selectedYear}
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-x-2">
            <div className="col-span-12 sm:col-span-9">
              <div className="grid grid-cols-12 mt-2">
                <div className="col-span-12 border h-auto rounded-md bg-white p-4">
                  <div className="flex flex-row justify-between items-center  border-b border-gray-200 px-6 mb-4">
                    <div className="">
                      <h4 className="text-blue-800">
                        <span className="text-blue-800 font-bold text-lg">
                          Claims Summary :
                        </span>
                        <span className="ml-1">{selectedYear}</span>
                      </h4>
                      <p className="text-gray-600 text-sm">
                        Claimed expenses in monthly view
                      </p>
                    </div>

                    <div className="">{y}</div>
                  </div>
                  <MainChart claims={claims} />
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-3">
              <div className="mt-2">
                <MonthlyWidget claims={claims} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-3">
          <div className="col-span-12 sm:col-span-6 border h-32 rounded-md bg-white mb-2">
            <CountWidget
              isLoading={isLoading}
              claims={claims}
              year={selectedYear}
            />
          </div>

          <div className="">
            <CategWidget claims={claims} constants={constants} />
          </div>
        </div>
      </div>
    </>
  );
}
