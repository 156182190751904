import { Button, Form, Input, message, Popover, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { BsEye, BsSortUp } from "react-icons/bs";
import { useNavigate } from "react-router";
import fetchDataClaim from "../../services/fetchDataClaim";
import { claimStatus } from "../../utils/data";
import formatMoney from "../../utils/formatMoney";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../utils/exportExcel";
import Moment from "react-moment";
import { AuthContext } from "../../contexts/AuthContext";
import Toastify from "toastify-js";
import { pusher } from "../..";
import NoResults from "../../components/shared/NoResults";
import moment from "moment";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import postDataMain from "../../services/postDataMail";
import {
  AiOutlineCheckSquare,
  AiOutlineCloseSquare,
  AiOutlineDelete,
} from "react-icons/ai";
import DeleteClaim from "./components/DeleteClaim";
import Manage from "../manage";
import UpdateCountry from "./components/UpdateCountry";

export default function AdmClaims() {
  const { user } = useContext(AuthContext);
  const [claims, setClaims] = useState<any>([]);
  const [selected, setSelected] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [countryModal, setCountryModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [currCursor, setCurrCursor] = useState<any>(0)
  const [nextcursor, setNextCursor] = useState<any>(0)

  const navigate = useNavigate();

  const [emailForm] = Form.useForm();

  const handleFetchClaims = async () => {
    setLoading(true);
    const uri = `claim/all/paginated?cursor=${currCursor}`;
    const response = await fetchDataClaim({ uri });
    setClaims(response?.data);
    setNextCursor(response?.paging?.nextCursor)
    setLoading(false);
  };

  const handleNextClaims = async () => {
    setLoading(true);
    const uri = `claim/all/paginated?cursor=${nextcursor}`;
    const response = await fetchDataClaim({ uri });
    setClaims(response?.data);
    setCurrCursor(nextcursor)
    setNextCursor(response?.paging?.nextCursor)
    setLoading(false);
  };


  const handlePrevClaims = async () => {
    setLoading(true);
    const uri = `claim/all/paginated?cursor=${currCursor}`;
    const response = await fetchDataClaim({ uri });
    setClaims(response?.data);
    setCurrCursor(nextcursor)
    setNextCursor(response?.paging?.nextCursor)
    setLoading(false);
  };

  const handleReFetchClaims = async () => {
    const uri = `claim/all/paginated?cursor=${currCursor}`;
    const response = await fetchDataClaim({ uri });
    setClaims(response?.payload);
    setNextCursor(response?.paging?.nextCursor)
    setLoading(false);
  };



  // const verifyYear = (claim: any) => {
  //   let isOk = true;
  //   if(claim?.expense_year === null) {
  //     isOk = false
  //   }
  //   // let year = new Date(claim.expense_date).getFullYear();
  //   // if (year !== claim?.expense_year) {
  //   //   isOk = false;
  //   // }
  //   return isOk;
  // };

  // const onFixExpenseYear = async (claim: any) => {
  //   setLoading(true);
  //   let isOk = verifyYear(claim);
  //   if (isOk) return;

  //   let year = claim?.claim_date.slice(0,4)
  //   let month = months[claim?.claim_date.slice(4,6)]

  //   const data = { expense_year: year, expense_month: month };

  //   const uri = `claim/${claim?._id}/update/year`;
  //   const response = await postDataClaim(data, uri);

  //   if (response.success) {
  //     setSwalProps({
  //       show: true,
  //       title: "Success",
  //       text: `Expense Updated Successfully`,
  //       icon: "success",
  //     });
  //     setLoading(false);
  //     setSwalProps({});
  //     handleFetchClaims()
  //   } else {
  //     setSwalProps({
  //       show: true,
  //       title: "Error",
  //       text: "Sorry, Something went wrong",
  //       icon: "error",
  //     });
  //     setLoading(false);
  //     setSwalProps({});
  //   }
  //   setLoading(false);
  // };


  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = claims?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Staff: item?.claimer?.name === null ? "-" : item?.claimer?.name,
        Requested:
          item?.createdAt === null
            ? "-"
            : moment(item?.createdAt).format("DD/MM/YYYY"),
        "Expense Date":
          item?.claim_date === null
            ? "-"
            : moment(item?.claim_date).format("DD/MM/YYYY"),
        "Expense Month":
          item?.expense_month === null ? "-" : item?.expense_month,
        "Total Amount": getTotal(item?.items),
        Status: item?.status,
      };
    });

    const raw = getExportData(data);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMain(maildata, mailuri);
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  const getTotal = (items: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + item?.amount;
    });

    return total;
  };

  const dummy = new Array(12).fill({ id: 1 });

  useEffect(() => {
    handleFetchClaims();
    const book = pusher.subscribe(`claim`);
    book.bind("submited", function (data: any) {
      handleReFetchClaims();
      Toastify({
        text: `New Claim Submitted`,
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        style: {
          marginTop: "80px",
          background: "#16A34A",
          fontWeight: "bold",
        },
        stopOnFocus: true,
        onClick: function () {},
      }).showToast();
    });

    book.bind("approve", function (data: any) {
      handleReFetchClaims();
    });

    return () => {
      pusher.unsubscribe("claim");
    };
  }, []);

  useEffect(() => {
    handleFetchClaims();
  }, []);
  return (
    <Manage
      title={"Expense"}
      subtitle={"Claims"}
      description={"List of all expense claims"}
      actions={
        <div className="flex flex-end">
          <Popover
            content={
              <>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  form={emailForm}
                  onFinish={sendMail}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Email is Required" }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is Required" }]}
                  >
                    <Input placeholder="Email Subject" />
                  </Form.Item>
                  <div className="flex flex-row justify-between">
                    <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                      <Button type="primary" htmlType="submit">
                        {isMailLoading ? "Sending ..." : "Submit"}
                      </Button>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                      <Button
                        onClick={() => {
                          toggleMessageModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            }
            title="Message Content"
            trigger="focus"
            placement="left"
            open={messageModal}
          >
            <Tooltip title="Send Email">
              <button
                className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={async () => {
                  toggleMessageModal(true);
                }}
              >
                <RiMailSendLine size={21} />
              </button>
            </Tooltip>
          </Popover>
          <Tooltip title="Export To Excel">
            <button
              color="#E50000"
              className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
              onClick={() =>
                ExportToExcel(
                  claims?.map((item: any, index: number) => {
                    return {
                      "S/N": index + 1,
                      Staff:
                        item?.claimer?.name === null
                          ? "-"
                          : item?.claimer?.name,
                      Requested:
                        item?.createdAt === null
                          ? "-"
                          : moment(item?.createdAt).format("DD/MM/YYYY"),
                      "Expense Date":
                        item?.claim_date === null
                          ? "-"
                          : moment(item?.claim_date).format("DD/MM/YYYY"),
                      "Expense Month":
                        item?.expense_month === null
                          ? "-"
                          : item?.expense_month,
                      "Total Amount": getTotal(item?.items),
                      Status: item?.status,
                    };
                  }),
                  "Claims.xlsx"
                )
              }
            >
              <RiFileExcel2Line size={20} />
            </button>
          </Tooltip>
        </div>
      }
    >
      <div className="grid gap-x-0 grid-cols-12">
        <div className="col-span-12 sm:col-span-12 md:col-span-12">
          <div className="flex flex-col">
            <div className="flex flex-col w-full">
              <div className="grid gap-x-0 grid-cols-12">
                <div className="col-span-12 sm:col-span-12 md:col-span-12">
                  <div className=" bg-white rounded-md">
                    <div className="grid grid-cols-12 gap-0 bg-gray-50 py-2 px-4 border-b border-t mb-2">
                      <div className="col-span-12 sm:col-span-1">S/N</div>
                      <div className="col-span-12 sm:col-span-2">
                        <div className="flex  items-center">
                          <BsSortUp />
                          Name
                        </div>
                      </div>
                      <div className="col-span-12 sm:col-span-1">
                        <div className="flex  items-center">
                          <BsSortUp />
                          Requested
                        </div>
                      </div>
                      <div className="col-span-12 sm:col-span-2">
                        <div className="flex  items-center">
                          <BsSortUp />
                          Date
                        </div>
                      </div>
                      <div className="col-span-12 sm:col-span-1">
                        <div className="flex  items-center">
                          <BsSortUp />
                          Submitted
                        </div>
                      </div>
                      <div className="col-span-12 sm:col-span-2 text-right px-2">
                        <div className="flex justify-end  items-center">
                          <BsSortUp />
                          Amount
                        </div>
                      </div>
                      <div className="col-span-12 sm:col-span-1 text-center">
                        Country
                      </div>
                      <div className="col-span-12 sm:col-span-1 text-center">
                        Status
                      </div>
                      <div className="col-span-12 sm:col-span-1 text-center">
                        Action
                      </div>
                    </div>
                    <div className="">
                      {isLoading ? (
                        <>
                          {dummy?.map((claim: any, index: number) => (
                            <div
                              className=" h-10 mb-2 hover:cursor-pointer bg-gray-100"
                              onClick={() =>
                                navigate(`/manage/claims/${claim?._id}/details`)
                              }
                              key={index}
                            >
                              <div className="grid grid-cols-12 gap-0 py-2 px-4 text-gray-100">
                                <div className="col-span-12 sm:col-span-1">
                                  -
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                  -
                                </div>

                                <div className="col-span-12 sm:col-span-1">
                                  -
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                  -
                                </div>
                                <div className="col-span-12 sm:col-span-1">
                                  -
                                </div>
                                <div className="col-span-12 sm:col-span-2 text-right px-2">
                                  -
                                </div>
                                <div className="col-span-12 sm:col-span-1 text-center">
                                  -
                                </div>
                                <div className="col-span-12 sm:col-span-1 text-center">
                                  -
                                </div>
                                <div className="col-span-12 sm:col-span-1 text-center">
                                  -
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          {claims?.length === 0 ? (
                            <div className="flex justify-center items-center h-96">
                              <NoResults title="No Claims Requested" />
                            </div>
                          ) : (
                            <>
                              {claims?.map((claim: any, index: number) => (
                                <div
                                  style={{
                                    background: `${claimStatus[claim?.status]}`,
                                  }}
                                  className=" h-10 mb-2"
                                  key={index}
                                >
                                  <div className="grid grid-cols-12 gap-0 py-2 px-4 text-gray-600">
                                    <div className="col-span-12 sm:col-span-1">
                                      {index + 1}
                                    </div>
                                    <div className="col-span-12 sm:col-span-2">
                                      {claim?.claimer?.name
                                        ? claim?.claimer?.name
                                        : "-"}
                                    </div>
                                    <div className="col-span-12 sm:col-span-1">
                                      <Moment fromNow>
                                        {claim?.createdAt}
                                      </Moment>
                                    </div>

                                    <div className="col-span-12 sm:col-span-2">
                                      {claim?.expense_date === null ? (
                                        "-"
                                      ) : (
                                        <Moment format="Do MMM, YYYY">
                                          {claim?.expense_date}
                                        </Moment>
                                      )}
                                    </div>
                                    <div className="col-span-12 sm:col-span-1">
                                      {claim?.isFinished ? (
                                        <div className="flex justify-center items-center">
                                          <div className="mb-1 mr-1">Yes</div>

                                          <AiOutlineCheckSquare color="#33cc33" />
                                        </div>
                                      ) : (
                                        <div className="flex justify-center items-center">
                                          <div className="mb-1 mr-1">No</div>

                                          <AiOutlineCloseSquare color="#E50000" />
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-span-12 sm:col-span-2 text-right px-2">
                                      {formatMoney(getTotal(claim?.items))}
                                    </div>
                                    <button
                                      onClick={() => {
                                        setSelected(claim);
                                        setCountryModal(true);
                                      }}
                                      className="col-span-12 sm:col-span-1 text-center"
                                    >
                                      {claim?.country}
                                    </button>
                                    <div className="col-span-12 sm:col-span-1 text-center">
                                      {claim?.status}
                                    </div>
                                    <div className="col-span-12 sm:col-span-1 text-center">
                                      <button
                                        onClick={() => {
                                          setSelected(claim);
                                          setDeleteModal(true);
                                        }}
                                        className="border p-1 bg-red-100 rounded-md"
                                      >
                                        <AiOutlineDelete />
                                      </button>
                                      <button
                                        onClick={() =>
                                          navigate(
                                            `/manage/claims/${claim?._id}/details`
                                          )
                                        }
                                        className="border p-1 bg-blue-100 rounded-md ml-1"
                                      >
                                        <BsEye />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}

                              <div className="flex mb-2">
                                <div className="mx-2 p-2 border" onClick={() => handlePrevClaims()}>Prev</div>
                                <div className="mx-2 p-2 border" onClick={() => handleNextClaims()}>Next</div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <DeleteClaim
                  isVisible={deleteModal}
                  setVisible={setDeleteModal}
                  selected={selected}
                  refetch={handleReFetchClaims}
                />
                <UpdateCountry
                  isVisible={countryModal}
                  setVisible={setCountryModal}
                  selected={selected}
                  refetch={handleFetchClaims}
                />
                

              </div>
            </div>
          </div>
        </div>
      </div>
    </Manage>
  );
}
