import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";

export default function BackToolnav() {
  const navigate = useNavigate();
  return (
    <header className="bg-gray-50 px-4 sm:px-4 lg:px-4 border-b border-gray-300  ">
      <div className="flex flex-row items-center ">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center justify-center text-sm text-gray-600 py-2"
        >
          <BsChevronLeft />
          <span className="text-sm">Back</span>
        </button>
      </div>
    </header>
  );
}
