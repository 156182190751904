import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { BarsOutlined } from "@ant-design/icons";
import { FiCreditCard } from "react-icons/fi";
import { BsChevronDown } from "react-icons/bs";
import { Popover as ManPopover } from "@mantine/core";
import { useState } from "react";

export default function HomeToolnav({
  link,
  handleChangeStaff,
  users,
  user,
}: any) {
  const [opened, setOpened] = useState(false);
  let resolved = useResolvedPath(link.to);
  let match = useMatch({ path: resolved.pathname, end: true });

  const current = {
    _id: user?._id,
    firstname: user?.firstname,
    lastname: user?.lastname,
  };

  const [type, setType] = useState(current);

  const access = [5, 9];

  return (
    <header className="flex justify-between items-center bg-gray-50 px-4 sm:px-5 lg:px-5 border-b border-gray-300  ">
      <div className="flex flex-row items-center ">
        <NavLink
          to={`${link.to}`}
          className={`flex items-center text-sm text-gray-600 py-2 hover:text-toyota ${
            match ? "border-b border-toyota" : ""
          }`}
        >
          <BarsOutlined /> <span className="ml-1">{link?.name}</span>
        </NavLink>
      </div>
      <div className="">
        <ManPopover
          className="cursor:pointer focus:outline-none bg-blue-100  hover:text-white"
          opened={opened}
          onClose={() => setOpened(false)}
          target={
            <div
              onClick={() => setOpened((o) => !o)}
              className="border border-gray-300  py-1 px-2 cursor-pointer"
            >
              <div className="flex flex-row justify-between items-center bg-blue-100 text-black hover:text-blue-800">
                <div className="flex justify-center items-center">
                  <FiCreditCard />
                  <span className="ml-2 trancate">
                    {type?.firstname} {type?.lastname}
                  </span>
                </div>
                <div className="ml-2">
                  <BsChevronDown color={"#323232"} />
                </div>
              </div>
            </div>
          }
          width={260}
          position="bottom"
          withArrow
        >
          {access.includes(user?.accessLevel) ? (
            <div className="flex flex-col">
              <button
                className="cursor-pointer hover:text-toyota text-left focus:outline-none py-2 border-b hover:bg-gray-50 w-full"
                onClick={() => {
                  handleChangeStaff({
                    _id: 0,
                    firstname: "All",
                    lastname: "Staff",
                  });
                  setType({ _id: 0, firstname: "All", lastname: "Staff" });
                  setOpened((o) => !o);
                }}
                key={0}
              >
                All Staff
              </button>
              <div className="h-auto max-h-96 overflow-x-auto">
                {users?.map((user: any, index: number) => (
                  <button
                    className="hover:bg-gray-50 w-full"
                    key={index}
                    onClick={() => {
                      handleChangeStaff(user);
                      setType(user);
                      setOpened((o) => !o);
                    }}
                  >
                    <button className="flex justify-centercursor-pointer hover:text-toyota focus:outline-none py-2">
                      <div className="flex flex-row">
                        {user?.firstname} {user?.lastname}
                      </div>
                    </button>
                  </button>
                ))}
              </div>
            </div>
          ) : (
            <button
              className="hover:bg-gray-50 w-full"
              onClick={() => {
                handleChangeStaff(user);
                setType(user);
                setOpened((o) => !o);
              }}
            >
              <button className="flex justify-centercursor-pointer hover:text-toyota focus:outline-none py-2">
                <div className="flex flex-row">
                  {user?.firstname} {user?.lastname}
                </div>
              </button>
            </button>
          )}
        </ManPopover>
      </div>
    </header>
  );
}
