import React from "react";

interface AuthContainerProps {
  children: React.ReactNode;
  actions: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  description: React.ReactNode;
}
export default function Wrapper({
  children,
  actions,
  title,
  subtitle,
  description,
}: AuthContainerProps) {
  return (
    <div className="col-span-12 sm:col-span-12 md:col-span-12">
      <main className="md:flex px-4 sm:px-4 lg:px-4 py-2">
        <div className="bg-white align-middle min-w-full border overflow-hidden sm:rounded-lg">
          <div className="flex flex-row justify-between items-center  border-b border-gray-200 px-4 sm:px-6">
            <div className="pt-4">
              <h4 className="text-blue-800">
                <span className="text-blue-800 font-bold text-lg">
                  {title} :{" "}
                </span>
                <span className="ml-1">{subtitle}</span>
              </h4>
              <p className="text-gray-600 text-sm">{description}</p>
            </div>
            <div className="">{actions}</div>
          </div>
          {children}
        </div>
      </main>
    </div>
  );
}
