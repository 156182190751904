import { useEffect, useState } from "react";
import formatMoney from "../../../utils/formatMoney";
import claimsByMain from "../../../utils/claimsByMain";
import MonthlyPanel from "./MonthlyPanel";

export default function MonthlyWidget({ claims }: any) {
  const [data, setData] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);
  const [isVisible, setVisible] = useState<boolean>(false);

  const handleFetchYearlyData = async () => {
    const data = claimsByMain({ claims });
    setData(data?.data);
  };

  const getTotal = () => {
    let total = 0;
    data.forEach((item: any) => {
      total = total + item?.Amount;
    });
    return total;
  };

  const total = getTotal();

  useEffect(() => {
    handleFetchYearlyData();
  }, [claims]);

  return (
    <div>
      {data?.map((item: any, index: number) => (
        <div
          className="border p-2 bg-white mb-1 hover:bg-blue-50 hover:cursor-pointer"
          key={index}
        >
          <button
            className="flex justify-between items-center w-full "
            onClick={() => {
              setSelected(item);
              setVisible(true);
            }}
          >
            <div className="text-gray-600">{item?.month}</div>
            <div className="">
              {item?.Amount
                ? formatMoney(item?.Amount ? item?.Amount : 0)
                : "-"}
            </div>
          </button>
        </div>
      ))}
      <div className="border p-2 bg-white mb-1 hover:bg-blue-50 hover:cursor-pointer">
        <button
          className="flex justify-between items-center w-full "
          onClick={() => {}}
        >
          <div className="text-white">-</div>
          <div className="text-white">-</div>
        </button>
      </div>

      <div className="border-t-2 border-black p-2 bg-white mb-1">
        <div className="flex justify-between items-center">
          <div className="">Total</div>
          <div className="">{total ? formatMoney(total ? total : 0) : "-"}</div>
        </div>
      </div>

      <MonthlyPanel
        isVisible={isVisible}
        setVisible={setVisible}
        selected={selected}
      />
    </div>
  );
}
