import { useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";
import { useNavigate, useParams } from "react-router-dom";
import fetchDataClaim from "../../services/fetchDataClaim";
import pht_avatar from "../../assets/images/photo.png";
import pdf_avatar from "../../assets/images/pdf.png";
import { BsDownload, BsEye, BsEyeFill } from "react-icons/bs";
import { HiOutlineChevronLeft } from "react-icons/hi";
import formatSize from "../../utils/formatSize";
import { claimStatus } from "../../utils/data";
import Moment from "react-moment";
import DocumentWrapper from "../../components/docum/DocumentWrapper";
import ReactToPrint from "react-to-print";
import {
  AiOutlineCheckSquare,
  AiOutlineCloseSquare,
  AiOutlinePrinter,
} from "react-icons/ai";
import NoResults from "../../components/shared/NoResults";
import base64ToFile from "../../utils/base64ToFile";
import LoadingClaim from "./components/LoadingClaim";
import Manage from "../manage";

export default function AdmClaim() {
  const [claim, setClaim] = useState<any>([]);
  const [updateItemModal, setUpdateItemModal] = useState<boolean>(false);
  const [deleteItemModal, setDeleteItemModal] = useState<boolean>(false);
  const [isEditable, setEditable] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const [preview, setPreview] = useState<any>("");

  const docRef = useRef(null);
  const navigate = useNavigate();

  let { claim_id } = useParams();

  const handleFetchClaim = async () => {
    setLoading(true);
    const uri = `claim/${claim_id}`;
    const response = await fetchDataClaim({ uri });
    setClaim(response?.payload);
    setLoading(false);
  };

  const downloadBase64Data = (base64String: any, fileName: any) => {
    let file = base64ToFile(base64String, fileName);
    saveAs(file, fileName);
  };

  const getTotal = (items: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + item?.amount;
    });
    return total;
  };

  const total = getTotal(claim?.items);

  const attachments = claim?.items?.filter(
    (item: any) => item?.hasReceipt === true
  );

  useEffect(() => {
    handleFetchClaim();
  }, []);

  if (isLoading || claim === undefined) {
    return <LoadingClaim />;
  }

  return (
    <>
      <Manage
        title={
          <div
            className="flex flex-row  items-center hover:text-toyota hover:cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <span className="mr-1">
              <HiOutlineChevronLeft />
            </span>
            {claim?.claimer?.name}
          </div>
        }
        subtitle={
          <>
            <span
              style={{
                background: claimStatus[claim?.status],
                padding: 4,
                borderWidth: 1,
                marginRight: 4,
              }}
            >
              {claim?.status}
            </span>
            Created : {<Moment fromNow>{claim?.createdAt}</Moment>}
          </>
        }
        description={<></>}
        actions={
          <div className="flex justify-center items-center text-sm">
            <ReactToPrint
              trigger={() => {
                return (
                  <button className="flex justify-center border p-2 items-center focus:outline-none  mr-2 text-sm hover:text-toyota">
                    <AiOutlinePrinter className="mr-1" /> Print
                  </button>
                );
              }}
              content={() => docRef.current}
              documentTitle={`Claim`}
            />
          </div>
        }
      >
        <div>
          <div className="flex flex-row bg-whitish overflow-auto">
            <div className="grid grid-cols-12 gap-0 bg-gray-50 w-full">
              <div className="col-span-12 sm:col-span-9">
                <div className="flex justify-start ml-10">
                  <div className="book" ref={docRef}>
                    <div className="page" ref={docRef}>
                      <div className="subpage">
                        {isLoading ? null : (
                          <DocumentWrapper
                            statements={2}
                            selected={selected}
                            claim={claim}
                            getTotalDue={() => {}}
                            setSelected={setSelected}
                            isEditable={isEditable}
                            updateItemModal={updateItemModal}
                            setUpdateItemModal={setUpdateItemModal}
                            deleteItemModal={deleteItemModal}
                            setDeleteItemModal={setDeleteItemModal}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-3 bg-white border-l px-6">
                <div className="flex border-b-2 py-2">
                  <div className="flex items-center justify-center">
                    <div className="pb-1">Submitted:</div>
                    <div className="ml-2">
                      {claim?.isFinished ? (
                        <AiOutlineCheckSquare color="#33cc33" />
                      ) : (
                        <AiOutlineCloseSquare color="#E50000" />
                      )}
                    </div>
                  </div>

                  <div className="flex items-center ml-4">
                    <div className="pb-1">Approval:</div>
                    <div className="ml-2">
                      {claim?.status === "Approved" ? (
                        <AiOutlineCheckSquare color="#33cc33" />
                      ) : null}
                      {claim?.status === "Declined" ? (
                        <AiOutlineCloseSquare color="#E50000" />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className=" border-b py-4">Attachments : </div>

                <div className="">
                  {attachments?.length === 0 ? (
                    <div className="mt-12">
                      <NoResults title={"No Attachments"} />
                    </div>
                  ) : null}
                  {attachments?.map((claim: any, index: number) => (
                    <div className="flex justify-between mt-2" key={index}>
                      <div className="flex">
                        <div className="mr-2">
                          <img
                            src={
                              claim.filetype === "application/pdf"
                                ? pdf_avatar
                                : pht_avatar
                            }
                            alt="Logo"
                            height={15}
                            width={35}
                          />
                        </div>
                        <div className="flex flex-col justify-center">
                          <div className="">{claim?.category}</div>
                          <div className="text-xs">
                            {formatSize(claim?.filesize)}
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-center items-center">
                        {claim?.filetype === "application/pdf" ? (
                          <a
                            href={`${claim?.attachment}`}
                            target="_blank"
                            className="ml-4 hover:text-green-600 text-black"
                          >
                            <BsDownload />
                          </a>
                        ) : (
                          <>
                            {preview?._id === claim?._id ? (
                              <button
                                onClick={() => setPreview("")}
                                className=""
                              >
                                <BsEyeFill color="#16a34a" />
                              </button>
                            ) : (
                              <button
                                onClick={() => setPreview(claim)}
                                className=""
                              >
                                <BsEye />
                              </button>
                            )}
                            <button
                              onClick={() => {
                                downloadBase64Data(
                                  claim?.attachment,
                                  "Receipt"
                                );
                                setPreview(claim);
                              }}
                              className="ml-4 hover:text-green-600"
                            >
                              <BsDownload />
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                  {preview ? (
                    <div className="border p-1 mt-4">
                      {preview?.filetype === "application/pdf" ? (
                        <div className="">
                          <a href={`${preview?.attachment}`} target="_black">
                            Pdf
                          </a>
                        </div>
                      ) : (
                        <img src={`${preview?.attachment}`} />
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Manage>
    </>
  );
}
