import * as React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

/*
|---------------------------------------------------------------------
|                             Main
|---------------------------------------------------------------------
*/
import AuthLayout from "./layouts/AuthLayout";
import HomeLayout from "./layouts/HomeLayout";
import Dashboard from "./pages/dashboard/Dashboard";
import MainLayout from "./layouts/MainLayout";
import Signin from "./pages/auth/Signin";
import Profile from "./pages/profile/MyProfile";

import "antd/dist/antd.min.css";
import "./custom.css";
import "./App.css";
import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import AllClaims from "./pages/claims/AllClaims";
import AllSafaris from "./pages/safaris/AllSafaris";
import Manage from "./pages/manage/users/AllUsers";
import OneClaim from "./pages/claims/OneClaim";
import AllUsers from "./pages/manage/users/AllUsers";
import AdmClaims from "./pages/claims/AdmClaims";
import AdmClaim from "./pages/claims/AdmClaim";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";
import AllCurrencies from "./pages/manage/currency/AllCurrencies";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<Signin />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset" element={<Reset />} />
        </Route>
        <Route element={<HomeLayout />}></Route>
        <Route element={<MainLayout />}>
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/claims"
            element={
              <RequireAuth>
                <AllClaims />
              </RequireAuth>
            }
          />
          <Route
            path="/safaris"
            element={
              <RequireAuth>
                <AllSafaris />
              </RequireAuth>
            }
          />
          <Route
            path="/claims/:claim_id/details"
            element={
              <RequireAuth>
                <OneClaim />
              </RequireAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path="/manage/users"
            element={
              <RequireAuth>
                <AllUsers />
              </RequireAuth>
            }
          />
          <Route
            path="/manage/claims"
            element={
              <RequireAuth>
                <AdmClaims />
              </RequireAuth>
            }
          />
          <Route
            path="/manage/currencies"
            element={
              <RequireAuth>
                <AllCurrencies />
              </RequireAuth>
            }
          />
          <Route
            path="/manage/claims/:claim_id/details"
            element={
              <RequireAuth>
                <AdmClaim />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = React.useContext(AuthContext);
  let location = useLocation();

  if (!auth.token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
