const claimsByMonth = function ({ claims, month }: any) {
  let total = 0;
  let data: any = [];
  claims?.forEach((claim: any) => {
    if (claim?.expense_month === `${month}`) {
      data.push(claim);
      claim?.items?.forEach((item: any) => {
        total = total + item?.amount;
      });
    }
  });

  return { total, data };
};

export default claimsByMonth;
