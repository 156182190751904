import { Button, Modal, Form, Row, Col, message, Select, Input } from "antd";
import { useEffect, useState } from "react";
import fetchDataClaim from "../../../services/fetchDataClaim";
import patchDataClaim from "../../../services/patchDataClaim";

const { Option } = Select;

export default function UpdateItem({
  isVisible,
  selected,
  setVisible,
  refetch,
}: any) {
  const [isLoading, setLoading] = useState(false);
  const [constants, setConstants] = useState<any>([]);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const { category, description, amount } = await form.getFieldsValue();

    const data = {
      category,
      description,
      amount: amount,
    };

    const uri = `claim/${selected?._id}/item/${selected?._id}`;
    const response = await patchDataClaim(data, uri);

    if (response.success === false) {
      message.error("Something went wrong");
      setLoading(false);
    } else {
      if (response.success) {
        message.success("Updated Successfully");
        await refetch();
        form.resetFields();
        hideModal();
        setLoading(false);
      } else {
        message.error(response.message);
        setLoading(false);
      }
    }
  };

  const hideModal = () => {
    setVisible(false);
    setLoading(false);
  };

  const handleFetchContants = async () => {
    const uri = `claim/constants/all`;
    const response = await fetchDataClaim({ uri });
    setConstants(response?.payload);
  };

  const categoryOptions = constants?.categories?.map(
    (category: any, index: number) => (
      <Option value={category} key={index}>
        {category}
      </Option>
    )
  );

  useEffect(() => {
    form.resetFields();
    handleFetchContants();
  }, [selected]);

  return (
    <Modal
      visible={isVisible}
      title="Update Expense Item"
      onCancel={hideModal}
      footer={[
        <Button key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateItem"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateItem"
        form={form}
        layout="vertical"
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="category"
              label="Expense Category"
              initialValue={selected?.category}
              rules={[
                {
                  required: true,
                  message: "Please select category",
                },
              ]}
            >
              <Select placeholder="Expense Category">{categoryOptions}</Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Description"
              initialValue={selected?.description}
              rules={[
                {
                  required: true,
                  message: "Please enter date",
                },
              ]}
            >
              <Input placeholder="Description" className="w-full" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="amount"
              label="Expense Amount"
              initialValue={selected?.amount}
              rules={[
                {
                  required: true,
                  message: "Please enter date",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Expense Amount"
                className="w-full"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
