import { useEffect, useState } from "react";
import { Button, Modal, Form, message } from "antd";
import formatMoney from "../../../utils/formatMoney";
import postDataClaim from "../../../services/postDataClaim";
import SweetAlert2 from "react-sweetalert2";

export default function SubmitClaim({
  selected,
  isVisible,
  setVisible,
  refetch,
  claim,
  total,
}: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [swalProps, setSwalProps] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {}, [selected]);

  const onSubmit = async () => {
    setLoading(true);
    if (total === 0) {
      setSwalProps({
        show: true,
        title: "Error",
        text: "Please add items to this claim",
        icon: "error",
      });
      setLoading(false);
      setVisible(false);
      setSwalProps({});
      return;
    }
    const data = {};
    const uri = `claim/${claim?._id}/save`;
    const response = await postDataClaim(data, uri);
    if (response.success) {
      setSwalProps({
        show: true,
        title: "Submitted",
        text: "Expense Claim Submited Successfully",
        icon: "success",
      });
      await refetch();
      setLoading(false);
      setVisible(false);
      setSwalProps({});
    } else {
      setSwalProps({
        show: true,
        title: "Error",
        text: "Sorry, Something went wrong",
        icon: "error",
      });
      await refetch();
      setVisible(false);
      setLoading(false);
      setSwalProps({});
    }
    setVisible(false);
    setLoading(false);
  };

  const hideModal = async () => {
    setVisible(false);
  };

  return (
    <Modal
      open={isVisible}
      title="Submit Claim"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="submitClaim"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form id="submitClaim" form={form} onFinish={onSubmit} layout="vertical">
        <div className="flex flex-col justify-center items-center px-4">
          <div className="mb-4">
            Are you sure you want to submit this claim?
          </div>
          <div className="pb-4 flex flex-col justify-center items-center">
            <div className="flex text-black text-lg">Total Amount</div>
            <div className="flex text-xl font-bold">{formatMoney(total)}</div>
          </div>
        </div>
      </Form>
      <SweetAlert2 {...swalProps} />
    </Modal>
  );
}
