import { Button, Modal, Form, Row, Col, message, DatePicker } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SweetAlert2 from "react-sweetalert2";
import { AuthContext } from "../../../contexts/AuthContext";
import postDataClaim from "../../../services/postDataClaim";
import { months } from "../../../utils/data";

export default function CreateClaim({
  isVisible,
  selected,
  setVisible,
  refetch,
}: any) {
  const { user }: any = useContext(AuthContext);
  const navigation = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [swalProps, setSwalProps] = useState({});

  const [form] = Form.useForm();

  function disabledDate(value: any) {
    const date = new Date();
    const start = moment(date).startOf("month").format("YYYY-MM-DD").toString();
    const end = moment(date).endOf("month").format("YYYY-MM-DD").toString();
    return !value || value.isSame(end) || value.isSameOrBefore(start);
  }

  const onSubmit = async () => {
    setLoading(true);
    const { expense_date } = await form.getFieldsValue();

    const year = new Date(expense_date).getFullYear();
    const currentYear = new Date().getFullYear()

    const month = new Date(expense_date).getMonth() + 1;
    const expense_month = months[month];

    const data = {
      expense_month,
      expense_date,
      expense_year: year || currentYear,
      claimer_email: user?.email,
      claimer_name: `${user?.firstname} ${user?.lastname}`,
      company: `${user?.firm?.name}`,
      address1: user?.firm?.address1,
      address2: user?.firm?.address2,
      address3: user?.firm?.address3,
      address4: user?.firm?.address4,
      logo: user?.firm?.logo,
      website: user?.firm?.website,
    };

    const uri = "claim";

    const response = await postDataClaim(data, uri);
    if (response.success === false) {
      message.error("Something went wrong");
      setLoading(false);
    } else {
      if (response.success) {
        setSwalProps({
          show: true,
          title: "Created",
          text: "Expense Claim Created Successfully",
          icon: "success",
        });
        navigation(`/claims/${response?.payload?._id}/details`);
        await refetch();
        form.resetFields();
        hideModal();
        setLoading(false);
      } else {
        message.error(response.message);
      }
    }
    setLoading(false);
  };

  const hideModal = () => {
    setVisible(false);
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [selected, form]);

  return (
    <Modal
      visible={isVisible}
      title="Create Claim"
      onCancel={hideModal}
      footer={[
        <Button key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="createClaim"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          onClick={onSubmit}
        >
          Next
        </Button>,
      ]}
    >
      <Form
        id="updateDetails"
        form={form}
        layout="vertical"
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="expense_date"
              label="Expense Date"
              rules={[
                {
                  required: true,
                  message: "Please enter date",
                },
              ]}
            >
              <DatePicker
                format={"Do MMM, YYYY"}
                className="w-full"
                disabledDate={disabledDate}
              />

              {/* <DatePicker
                disabledDate={(d) =>
                  !d ||
                  d.isAfter(
                    moment(new Date()).startOf("month").format("DD-MM-YYYY")
                  ) ||
                  d.isSameOrBefore("1960-01-01")
                }
                format="DD-MM-YYYY"
                // defaultPickerValue={moment("2002-12-31")}
                // placeholder="Start"
              /> */}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <SweetAlert2 {...swalProps} />
    </Modal>
  );
}
