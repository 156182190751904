import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { BarsOutlined } from "@ant-design/icons";

export default function PageToolnav({ link }: any) {
  let resolved = useResolvedPath(link.to);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <header className="bg-gray-50 px-4 sm:px-5 lg:px-5 border-b border-gray-300  ">
      <div className="flex flex-row items-center ">
        <NavLink
          to={`${link.to}`}
          className={`flex items-center text-sm text-gray-600 py-2 hover:text-toyota ${
            match ? "border-b border-toyota" : ""
          }`}
        >
          <BarsOutlined /> <span className="ml-1">{link?.name}</span>
        </NavLink>
      </div>
    </header>
  );
}
