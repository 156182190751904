import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import CreateUser from "./CreateUser";

const CreateWrapper = ({ refetch }: any) => {
  const [isVisible, setVisible] = useState(false);

  return (
    <>
      <button
        onClick={() => setVisible(true)}
        className="border rounded-md px-2 py-1 flex items-center"
      >
        <AiOutlinePlus />
        <span className="ml-2">New</span>
      </button>
      <CreateUser
        isVisible={isVisible}
        setVisible={setVisible}
        refetch={refetch}
      />
    </>
  );
};

export default CreateWrapper;
