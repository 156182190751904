import { BsSearch } from "react-icons/bs";

export default function AllSafaris() {
  return (
    <div className="flex flex-col justify-center items-center w-full pt-32">
      <div className="">
        <BsSearch />
      </div>
      <div className="">Coming Soon</div>
    </div>
  );
}
