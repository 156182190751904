import { useContext } from "react";
import Moment from "react-moment";
import { AuthContext } from "../../contexts/AuthContext";

export default function DocumentInfo({ claim }: any) {
  const month = new Date(claim?.createdAt).getMonth();
  const year = new Date(claim?.createdAt).getFullYear();
  const { user } = useContext(AuthContext);
  return (
    <div className="flex justify-between mb-10 mt-10">
      <div className="border border-black w-full">
        <div className="flex border-b border-black">
          <div className="w-40 border-r border-black px-2 font-black">
            Employee Name
          </div>
          <div className="px-2">{claim?.claimer?.name}</div>
        </div>
        <div className="flex border-b border-black">
          <div className="w-40 border-r border-black px-2 font-black">Date</div>
          <div className="px-2">
            <Moment format={"DD/MM/YYYY"}>{claim?.createdAt}</Moment>
          </div>
        </div>
        <div className="flex">
          <div className="w-40 border-r border-black px-2 font-black">
            Expense Claim Form ID
          </div>
          <div className="px-2">EXP-{month}-{year}</div>
        </div>
      </div>
      <div className="mx-4"></div>
      <div className="w-full">
        <div className="flex border-b border-t border-l border-r border-black">
          <div className="w-28 border-r border-black px-2 font-black">
            Branch
          </div>
          <div className="px-2">{user?.firm?.name}</div>
        </div>
        <div className="flex border-b border-l border-r border-black">
          <div className="w-28 border-r border-black px-2 font-black">
            Month
          </div>
          <div className="px-2">{claim?.expense_month}</div>
        </div>
      </div>
    </div>
  );
}
