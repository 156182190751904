import { Button, Modal, Form, Row, Col, message, Input } from "antd";
import { useEffect, useState } from "react";
import patchDataClaim from "../../../../services/patchDataClaim";

export default function EditUser({
  isVisible,
  selected,
  setVisible,
  refetch,
}: any) {
  const [isLoading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const uri = `claim/claimers/${selected?._id}/update/details`;
    const response = await patchDataClaim(values, uri);
    if (response.success === false) {
      message.error("Something went wrong");
    } else {
      if (response.success) {
        message.success("Updated Successfully");
        refetch();
        form.resetFields();
        hideModal();
      } else {
        message.error(response.message);
      }
    }
  };

  const hideModal = () => {
    setVisible(false);
  };

  const formatMobile = (mobile: any) => {
    let num = mobile ? mobile : "";
    let trim = num?.trim();
    let mob = trim?.slice(-9);
    return mob;
  };

  useEffect(() => {
    form.resetFields();
  }, [selected]);

  return (
    <Modal
      visible={isVisible}
      title="Update User"
      onCancel={hideModal}
      footer={[
        <Button key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateDetails"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateDetails"
        form={form}
        layout="vertical"
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Full name"
              initialValue={selected?.name}
              rules={[
                {
                  required: false,
                  message: "Please enter name",
                },
              ]}
            >
              <Input style={{ width: "100%" }} placeholder="Firstname" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label="Email"
              initialValue={selected?.email}
              rules={[
                {
                  required: false,
                  message: "Please enter email",
                },
              ]}
            >
              <Input style={{ width: "100%" }} placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              label="User Mobile"
              initialValue={formatMobile(selected?.mobile)}
              rules={[
                {
                  required: false,
                  message: "Please enter mobile number 1",
                },
              ]}
            >
              <Input style={{ width: "100%" }} placeholder="Mobile number 1" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile2"
              label="User Mobile 2"
              initialValue={formatMobile(selected?.mobile2)}
              rules={[
                {
                  required: false,
                  message: "Please enter mobile number 2",
                },
              ]}
            >
              <Input style={{ width: "100%" }} placeholder="Mobile number 2" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
