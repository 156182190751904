import {
  BarChartOutlined,
  CheckSquareTwoTone,
  CloseSquareTwoTone,
} from "@ant-design/icons";
import { useContext } from "react";
import Wrapper from "../../components/shared/Wrapper";
import PageToolnav from "../../components/toolnav/PageToolnav";
import { AuthContext } from "../../contexts/AuthContext";
import UpdateWrapper from "./components/UpdateWrapper";

export default function MyProfile() {
  const { user } = useContext(AuthContext);

  const shortname =
    user.firstname === null
      ? "--"
      : `${user.firstname.charAt(0)}${user.lastname.charAt(0)} `;

  return (
    <>
      <PageToolnav link={{ name: "My Profile", to: "/profile" }} />
      <Wrapper
        title="My Account"
        subtitle={`Details`}
        description="Update Details and Password"
        actions={<UpdateWrapper userDetails={user} />}
      >
        <div className="grid grid-cols-12 gap-4 m-4">
          <div className="col-span-12 sm:col-span-6 md:col-span-3 bg-white border rounded-lg  mb-4">
            <div className="flex flex-col justify-between items-center py-2">
              <div className="flex items-center justify-center h-24 w-24 rounded-full bg-gray-300">
                <span className="text-lg text-blue-900">{shortname}</span>
              </div>
              <span className="text-blue-800 text-sm mt-2 font-bold">
                {user.firstname} {user.lastname}
              </span>
              <span className="text-gray-600 text-sm ">{user.role?.name}</span>
              <span className="text-gray-600 text-sm ">{user?.mobile}</span>
              <h3>
                <span className="font-bold my-1">{user.site?.name} </span>
              </h3>
              <span className="text-blue-500 text-sm bg-gray-200 rounded-lg px-4 py-1 my-2">
                {user.email}
              </span>
            </div>
            <div className="flex justify-between items-center border-t bg-gray-100 rounded-br-lg rounded-bl-lg">
              <div className="flex items-center justify-center py-2 w-full text-center text-xs">
                <span className="mr-2">Account Status:</span>
                {user.isActive ? (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center">
                      <CheckSquareTwoTone twoToneColor="#52c41a" />
                    </div>
                    <div className="ml-1 text-green-500">Active</div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center">
                      <CloseSquareTwoTone twoToneColor="#ff0000" />
                    </div>
                    <div className="ml-1 text-red-500">In Active</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-9 bg-white border rounded-lg flex flex-col justify-center items-center mb-4">
            <div className="flex flex-col justify-center items-center text-gray-600 p-8">
              <BarChartOutlined />
              <div className="">Account Metrics</div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}
