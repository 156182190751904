import { BsTrash } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import formatMoney from "../../utils/formatMoney";

export default function DocumentEdit({
  item,
  setSelected,
  setUpdateItemModal,
  setDeleteItemModal,
}: any) {
  return (
    <div className="grid grid-cols-12 border-t border-black items-center text-gray-700">
      <div className="col-span-3 px-2  border-r border-black truncate  text-left">
        {item?.description}
      </div>
      <div className="col-span-3 px-2   border-r border-black truncate">
        {item?.category}
      </div>
      <div className="col-span-2 px-2  border-r border-black  text-center">
        {item?.hasReceipt ? "Yes" : "No"}
      </div>
      <div className="col-span-2 px-2 border-r border-black    text-right">
        {formatMoney(item?.amount ? item?.amount : 0)}
      </div>
      <div className="col-span-1 px-2 border-r border-black  text-center">
        <button
          onClick={() => {
            setSelected(item);
            setUpdateItemModal(true);
          }}
          className="cursor-pointer"
        >
          <FiEdit size={16} color="#eab308" />
        </button>
      </div>
      <div className="col-span-1 px-2  text-center">
        <button
          onClick={() => {
            setSelected(item);
            setDeleteItemModal(true);
          }}
          className="cursor-pointer"
        >
          <BsTrash size={16} color="red" />
        </button>
      </div>
    </div>
  );
}
