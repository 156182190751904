import { Button, Form, Input, message, Popover, Table, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Wrapper from "../../components/shared/Wrapper";
import PageToolnav from "../../components/toolnav/PageToolnav";
import fetchDataClaim from "../../services/fetchDataClaim";
import { claimColors } from "../../utils/data";
import CreateClaim from "./components/CreateClaim";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../utils/exportExcel";
import Moment from "react-moment";
import { AuthContext } from "../../contexts/AuthContext";
import Toastify from "toastify-js";
import { pusher } from "../..";
import moment from "moment";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import postDataMain from "../../services/postDataMail";
import { AiOutlineCheckSquare, AiOutlineCloseSquare } from "react-icons/ai";

import type { PaginationProps } from "antd";

export default function AllClaims() {
  const { user } = useContext(AuthContext);
  const [claims, setClaims] = useState<any>([]);
  const [selected, setSelected] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    limit: 10,
  });
  const navigate = useNavigate();

  const [emailForm] = Form.useForm();

  const handleFetchClaims = async ({ pagination }: any) => {
    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const uri = `claim/claimer/${user?.email}/paginated?skip=${skip}&limit=${limit}`;
    setLoading(true);
    const response = await fetchDataClaim({ uri });
    setPagination({ total: response?.count, ...pagination });
    setClaims(response?.results);
    setLoading(false);
  };

  const handleReFetchClaims = async ({ pagination }: any) => {
    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const uri = `claim/claimer/${user?.email}/paginated?skip=${skip}&limit=${limit}`;
    const response = await fetchDataClaim({ uri });
    setPagination({ total: response?.count, ...pagination });
    setClaims(response?.payload);
    setLoading(false);
  };

  // const handleFetchClaims = async ({ pagination }: any) => {
  //   const { current, limit } = pagination;
  //   const skip = current * limit - limit;
  //   const uri = `claim/claimer/${user?.email}/paginated?skip=${skip}&limit=${limit}`;
  //   setLoading(true);
  //   const response = await fetchDataClaim({ uri });
  //   if (
  //     user?.email === "akher@sfl.co.tz" ||
  //     user?.email === "farishta.kohli@karimjee.com"
  //   ) {
  //     const filtered = response?.results.filter(
  //       (claim: any) =>
  //         claim?.claimer?.country !== "Tanzania" ||
  //         claim.claimer?.email === "akher@sfl.co.tz" ||
  //         claim.claimer?.email === "farishta.kohli@karimjee.com"
  //     );
  //     setPagination({ total: response?.count, ...pagination });
  //     setClaims(filtered);
  //     setLoading(false);
  //     return;
  //   }

  //   if (
  //     user?.email === "ma@toyota.co.tz" ||
  //     user?.email === "jasmine@karimjee.com"
  //   ) {
  //     const filtered = response?.results.filter(
  //       (claim: any) => claim?.isFinished === true
  //     );
  //     setPagination({ total: response?.count, ...pagination });
  //     setClaims(filtered);
  //     setLoading(false);
  //     return;
  //   }

  //   setPagination({ total: response?.count, ...pagination });
  //   setClaims(response?.results);
  //   setLoading(false);
  // };

  // const handleReFetchClaims = async ({ pagination }: any) => {
  //   const { current, limit } = pagination;
  //   const skip = current * limit - limit;
  //   const uri = `claim/claimer/${user?.email}/paginated?skip=${skip}&limit=${limit}`;
  //   const response = await fetchDataClaim({ uri });
  //   if (
  //     user?.email === "akher@sfl.co.tz" ||
  //     user?.email === "farishta.kohli@karimjee.com"
  //   ) {
  //     const filtered = response?.payload.filter(
  //       (claim: any) =>
  //         claim?.claimer?.country !== "Tanzania" ||
  //         claim.claimer?.email === "akher@sfl.co.tz" ||
  //         claim.claimer?.email === "farishta.kohli@karimjee.com"
  //     );
  //     setPagination({ total: response?.count, ...pagination });
  //     setClaims(filtered);
  //     setLoading(false);
  //     return;
  //   }

  //   if (
  //     user?.email === "ma@toyota.co.tz" ||
  //     user?.email === "jasmine@karimjee.com"
  //   ) {
  //     const filtered = response?.payload.filter(
  //       (claim: any) => claim?.isFinished === true
  //     );

  //     setPagination({ total: response?.count, ...pagination });
  //     setClaims(filtered);
  //     setLoading(false);
  //     return;
  //   }

  //   setPagination({ total: response?.count, ...pagination });
  //   setClaims(response?.payload);
  //   setLoading(false);
  // };

  const columns = [
    {
      title: "S/N",
      width: 10,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },

    {
      title: "Requested",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.claimer === null ? "-" : record?.claimer?.name}
        </span>
      ),
    },
    {
      title: "Month",
      width: 40,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.expense_month === null ? "-" : record?.expense_month}
        </span>
      ),
    },
    {
      title: "Expense Date",
      width: 40,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.expense_date === null ? (
            "-"
          ) : (
            <>
              <Moment format="Do MMM, YYYY">{record?.expense_date}</Moment>
            </>
          )}
        </span>
      ),
    },
    {
      title: "Created Date",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.createdAt === null ? (
            "-"
          ) : (
            <>
              <Moment format="Do MMM, YYYY">{record?.createdAt}</Moment>
              <span className="mx-2">
                ( <Moment fromNow>{record?.createdAt}</Moment> )
              </span>
            </>
          )}
        </span>
      ),
    },
    {
      title: "Submitted",
      width: 40,
      render: (record: any) => (
        <>
          {record?.isFinished ? (
            <div className="flex items-center">
              <div className="mb-1 mr-1">Yes</div>
              <AiOutlineCheckSquare color="#33cc33" />
            </div>
          ) : (
            <div className="flex items-center">
              <div className="mb-1 mr-1">No</div>
              <AiOutlineCloseSquare color="#E50000" />
            </div>
          )}
        </>
      ),
    },

    {
      title: "Status",
      width: 40,
      render: (record: any) => (
        <span className="text-gray-700">{record?.status || "-"}</span>
      ),
    },
    {
      title: "Country",
      width: 80,
      render: (record: any) => (
        <span className="text-gray-700">{record?.country || "-"}</span>
      ),
    },
    // {
    //   title: "Details",
    //   width: 80,
    //   render: (record: any) => (
    //     <span className="text-gray-700 bg-blue-100 p-2 rounded-md">
    //       <Link to={`/claims/${record?._id}/details`}>Details</Link>
    //     </span>
    //   ),
    // },
  ];

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = claims?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Staff: item?.claimer?.name || "-",
        Requested:
          item?.createdAt === null
            ? "-"
            : moment(item?.createdAt).format("DD/MM/YYYY"),
        "Expense Date":
          item?.claim_date === null
            ? "-"
            : moment(item?.claim_date).format("DD/MM/YYYY"),
        "Expense Month": item?.expense_month || "-",
        "Total Amount": getTotal(item?.items),
        Status: item?.status,
      };
    });

    const raw = getExportData(data);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMain(maildata, mailuri);
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    console.log("Page: ", pageNumber);
  };

  const getTotal = (items: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + item?.amount;
    });

    return total;
  };

  const dummy = new Array(12).fill({ id: 1 });

  useEffect(() => {
    handleFetchClaims({ pagination });
    const book = pusher.subscribe(`claim`);
    book.bind("submited", function (data: any) {
      handleReFetchClaims({ pagination });
      Toastify({
        text: `New Claim Submitted`,
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        style: {
          marginTop: "80px",
          background: "#16A34A",
          fontWeight: "bold",
        },
        stopOnFocus: true,
        onClick: function () {},
      }).showToast();
    });

    book.bind("approve", function (data: any) {
      handleReFetchClaims({ pagination });
    });

    return () => {
      pusher.unsubscribe("claim");
    };
  }, []);

  const handleTableChange = (pagination: any) => {
    handleFetchClaims({
      pagination,
    });
  };

  useEffect(() => {
    handleFetchClaims({ pagination });
  }, []);

  return (
    <>
      <PageToolnav link={{ name: "Expense Claims", to: "/claims" }} />
      <Wrapper
        title="EXPENSE"
        subtitle={`Claims`}
        description="List of all expense claims"
        actions={
          <div className="flex">
            <Popover
              content={
                <>
                  <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    form={emailForm}
                    onFinish={sendMail}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: "Email is Required" }]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      label="Subject"
                      name="subject"
                      rules={[
                        { required: true, message: "Subject is Required" },
                      ]}
                    >
                      <Input placeholder="Email Subject" />
                    </Form.Item>
                    <div className="flex flex-row justify-between">
                      <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                        <Button type="primary" htmlType="submit">
                          {isMailLoading ? "Sending ..." : "Submit"}
                        </Button>
                      </Form.Item>

                      <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                        <Button
                          onClick={() => {
                            toggleMessageModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </>
              }
              title="Message Content"
              trigger="focus"
              placement="left"
              open={messageModal}
            >
              <Tooltip title="Send Email">
                <button
                  className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={async () => {
                    toggleMessageModal(true);
                  }}
                >
                  <RiMailSendLine size={21} />
                </button>
              </Tooltip>
            </Popover>
            <Tooltip title="Export To Excel">
              <button
                color="#E50000"
                className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={() =>
                  ExportToExcel(
                    claims?.map((item: any, index: number) => {
                      return {
                        "S/N": index + 1,
                        Staff:
                          item?.claimer?.name === null
                            ? "-"
                            : item?.claimer?.name,
                        Requested:
                          item?.createdAt === null
                            ? "-"
                            : moment(item?.createdAt).format("DD/MM/YYYY"),
                        "Expense Date":
                          item?.claim_date === null
                            ? "-"
                            : moment(item?.claim_date).format("DD/MM/YYYY"),
                        "Expense Month":
                          item?.expense_month === null
                            ? "-"
                            : item?.expense_month,
                        "Total Amount": getTotal(item?.items),
                        Status: item?.status,
                      };
                    }),
                    "Claims.xlsx"
                  )
                }
              >
                <RiFileExcel2Line size={20} />
              </button>
            </Tooltip>
            <Button onClick={() => setCreateModal(true)}>+ New</Button>
          </div>
        }
      >
        <div className="flex flex-col">
          <div className="flex flex-col w-full ">
            <div className="grid gap-x-0 grid-cols-12">
              <div className="col-span-12 sm:col-span-12 md:col-span-12">
                <Table
                  rowClassName={(record, index) =>
                    `${claimColors[record?.status]}`
                  }
                  size="middle"
                  dataSource={isLoading ? [] : claims}
                  columns={columns}
                  pagination={pagination}
                  scroll={{ y: 560, x: 0 }}
                  rowKey="id"
                  loading={isLoading}
                  onChange={handleTableChange}
                  onRow={(r) => ({
                    onClick: () => {
                      navigate(`/claims/${r._id}/details`);
                    },
                  })}
                />
              </div>
              <CreateClaim
                isVisible={createModal}
                setVisible={setCreateModal}
                selected={selected}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}
