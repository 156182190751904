const claimsByMain = function ({ claims }: any) {
  let total = 0;
  let [janTotal, febTotal, marTotal] = [0, 0, 0];
  let [aprTotal, mayTotal, junTotal] = [0, 0, 0];
  let [julTotal, augTotal, sepTotal] = [0, 0, 0];
  let [octTotal, novTotal, decTotal] = [0, 0, 0];

  let janData: any = [];
  let febData: any = [];
  let marData: any = [];
  let aprData: any = [];
  let mayData: any = [];
  let junData: any = [];
  let julData: any = [];
  let augData: any = [];
  let octData: any = [];
  let sepData: any = [];
  let novData: any = [];
  let decData: any = [];

  claims.forEach((claim: any) => {
    let total = 0;
    if (claim?.expense_month === "January") {
      janData.push(claim);
      claim?.items?.forEach((item: any) => {
        total = total + item?.amount;
        janTotal = janTotal + item?.amount;
      });
    }
    if (claim?.expense_month === "February") {
      febData.push(claim);
      claim?.items?.forEach((item: any) => {
        total = total + item?.amount;
        febTotal = febTotal + item?.amount;
      });
    }
    if (claim?.expense_month === "March") {
      marData.push(claim);
      claim?.items?.forEach((item: any) => {
        total = total + item?.amount;
        marTotal = marTotal + item?.amount;
      });
    }
    if (claim?.expense_month === "April") {
      aprData.push(claim);
      claim?.items?.forEach((item: any) => {
        aprTotal = aprTotal + item?.amount;
      });
    }
    if (claim?.expense_month === "May") {
      mayData.push(claim);
      claim?.items?.forEach((item: any) => {
        total = total + item?.amount;
        mayTotal = mayTotal + item?.amount;
      });
    }
    if (claim?.expense_month === "June") {
      junData.push(claim);
      claim?.items?.forEach((item: any) => {
        total = total + item?.amount;
        junTotal = junTotal + item?.amount;
      });
    }
    if (claim?.expense_month === "July") {
      julData.push(claim);
      claim?.items?.forEach((item: any) => {
        total = total + item?.amount;
        julTotal = julTotal + item?.amount;
      });
    }
    if (claim?.expense_month === "August") {
      augData.push(claim);
      claim?.items?.forEach((item: any) => {
        total = total + item?.amount;
        augTotal = augTotal + item?.amount;
      });
    }
    if (claim?.expense_month === "September") {
      sepData.push(claim);
      claim?.items?.forEach((item: any) => {
        total = total + item?.amount;
        sepTotal = sepTotal + item?.amount;
      });
    }
    if (claim?.expense_month === "October") {
      octData.push(claim);
      claim?.items?.forEach((item: any) => {
        total = total + item?.amount;
        octTotal = octTotal + item?.amount;
      });
    }
    if (claim?.expense_month === "November") {
      novData.push(claim);
      claim?.items?.forEach((item: any) => {
        total = total + item?.amount;
        novTotal = novTotal + item?.amount;
      });
    }
    if (claim?.expense_month === "December") {
      decData.push(claim);
      claim?.items?.forEach((item: any) => {
        total = total + item?.amount;
        decTotal = decTotal + item?.amount;
      });
    }
  });

  const data = [
    { name: "Jan", month: "January", Amount: janTotal, data: janData },
    { name: "Feb", month: "February", Amount: febTotal, data: febData },
    { name: "Mar", month: "March", Amount: marTotal, data: marData },
    { name: "Apr", month: "April", Amount: aprTotal, data: aprData },
    { name: "May", month: "May", Amount: mayTotal, data: mayData },
    {
      name: "Jun",
      month: "June",
      Amount: junTotal,
      data: junData,
    },
    {
      name: "Jul",
      month: "July",
      Amount: julTotal,
      data: julData,
    },
    { name: "Aug", month: "August", Amount: augTotal, data: augData },
    { name: "Sep", month: "September", Amount: sepTotal, data: sepData },
    { name: "Oct", month: "October", Amount: octTotal, data: octData },
    { name: "Nov", month: "November", Amount: novTotal, data: novData },
    { name: "Dec", month: "December", Amount: decTotal, data: decData },
  ];

  return { data, total };
};

export default claimsByMain;
