import { useEffect, useState } from "react";
import { FiChevronsRight } from "react-icons/fi";
import claimsByYear from "../../../utils/claimsByYear";
import MainPanel from "./MainPanel";
import Spinner from "./Spinner";

export default function CountWidget({ claims, year, isLoading }: any) {
  const [data, setData] = useState<any>({});
  const [isVisible, setVisible] = useState<boolean>(false);

  const handleFetchYTDData = async () => {
    const filtered = await claimsByYear({ claims });
    setData(filtered);
  };

  useEffect(() => {
    handleFetchYTDData();
  }, [claims]);

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col flex-1 p-4">
        <div className="text-gray-400 text-xs uppercase">
          TOTAL CLAIMS : {year}
        </div>

        <div className="font-bold text-toyota text-2xl">
          {isLoading ? (
            <Spinner />
          ) : data?.data?.length ? (
            data?.data?.length
          ) : (
            0
          )}
        </div>
      </div>
      <button
        onClick={() => setVisible(true)}
        className="flex justify-between items-center border-t h-10 p-4 hover:bg-blue-50 hover:cursor-pointer"
      >
        <div className="mb-2">See Details</div>
        <div className="">
          <FiChevronsRight />
        </div>
      </button>

      <MainPanel
        isVisible={isVisible}
        setVisible={setVisible}
        selected={data}
      />
    </div>
  );
}
