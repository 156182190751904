import { TRACE_BASE_URL } from "../utils/urls";

const patchDataTrace = async (data: any, uri: any) => {
  const response = await fetch(`${TRACE_BASE_URL}/${uri}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return await response.json();
};

export default patchDataTrace;
