import { CLAIM_BASE_URL } from "../utils/urls";

const fetchDataClaim = async ({ uri }: any) => {
  const response = await fetch(`${CLAIM_BASE_URL}/${uri}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

export default fetchDataClaim;
