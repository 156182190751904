import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function AdminLink({ menu }: any) {
  const { to, icon, name } = menu;
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });

  return (
    <div
      className={`flex justify-center items-center mb-6 ${
        match ? "border-l-2" : ""
      }`}
    >
      <Link to={to}>
        <div className="flex flex-col justify-center items-center">
          <div
            className={`flex flex-col justify-center items-center bg-toyota h-12 w-12 rounded-lg ${
              match ? "bg-toyota-pale " : "bg-toyota-dark"
            }`}
          >
            <div className="">{icon}</div>
          </div>
          <div className={`text-xs  ${match ? "text-white" : "text-white"}`}>
            {name}
          </div>
        </div>
      </Link>
    </div>
  );
}
