const claimsByYear = function ({ claims }: any) {
  let total = 0;
  let data: any = [];
  claims?.forEach((claim: any) => {
    data.push(claim);
    claim?.items?.forEach((item: any) => {
      total = total + item?.amount;
    });
  });

  return { total, data };
};

export default claimsByYear;
