import { Table } from "antd";
import { FiEdit } from "react-icons/fi";
import Manage from "..";
import { useEffect, useState } from "react";
import UpdateUser from "./components/UpdateUser";
import DeleteUser from "./components/DeleteUser";
import CreateWrapper from "./components/CreateWrapper";
import UpdateApr from "./components/UpdateApr";
import fetchDataClaim from "../../../services/fetchDataClaim";
import EditUser from "./components/EditUser";
import UpdateCountry from "./components/UpdateCountry";
import UpdateScope from "./components/UpdateScope";

export default function AllUsers() {
  const [updateModal, setUpdateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [countryModal, setCountryModal] = useState(false);
  const [scopeModal, setScopeModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [approverModal, setApproverModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const handleFetchUsers = async () => {
    setLoading(true);
    const uri = "claim/claimers/all";
    const response = await fetchDataClaim({ uri: uri });
    setUsers(response?.payload);
    setLoading(false);
  };

  useEffect(() => {
    handleFetchUsers();
  }, []);

  const columns = [
    {
      title: "S/N",
      width: 20,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },
    {
      title: "Name",
      width: 50,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.name === null ? "-" : `${record?.name}`}
        </span>
      ),
    },
    {
      title: "Country",
      width: 40,
      render: (record: any) => (
        <div className="flex items-center text-gray-700">
          <span>{record?.country || "-"}</span>
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setCountryModal(true);
            }}
            className="flex justify-center items-center text-gray-700 px-2 py-1 cursor-pointer"
          >
            <FiEdit />
          </button>
        </div>
      ),
    },
    {
      title: "Scope",
      width: 40,
      render: (record: any) => (
        <div className="flex items-center text-gray-700">
          <span>{record?.aprScope || "-"}</span>
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setScopeModal(true);
            }}
            className="flex justify-center items-center text-gray-700 px-2 py-1 cursor-pointer"
          >
            <FiEdit />
          </button>
        </div>
      ),
    },
    {
      title: "Email",
      width: 60,
      render: (record: any) => (
        <span className="text-gray-700 truncate">
          {record?.email === null ? "-" : `${record?.email}`}
        </span>
      ),
    },
    {
      title: "Mobile 1",
      width: 40,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.mobile === undefined ? "-" : `${record?.mobile}`}
        </span>
      ),
    },
    {
      title: "Approver",
      width: 40,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.canApprove ? "Yes @ Level " : "No"}
          {record?.canApprove ? record?.aprLevel : ""}
        </span>
      ),
    },

    {
      title: "Action",
      width: 60,
      render: (record: any) => (
        <div className="flex items-center">
          <div className="flex mr-2">
            <button
              onClick={() => {
                setSelected({
                  ...record,
                });
                setEditModal(true);
              }}
              className="flex justify-center items-center text-gray-700 border rounded-md px-2 py-1 bg-yellow-100 hover:bg-yellow-200 cursor-pointer"
            >
              <FiEdit />
              <span className="ml-1">Edit</span>
            </button>
          </div>
          <div className="flex">
            {record?.canApprove ? (
              <button
                onClick={() => {
                  setSelected({
                    ...record,
                  });
                  setUpdateModal(true);
                }}
                className="flex justify-center items-center text-gray-700 border rounded-md px-2 py-1 bg-yellow-100 hover:bg-yellow-200 cursor-pointer"
              >
                <FiEdit />
                <span className="ml-1">Change Level</span>
              </button>
            ) : (
              <button
                onClick={() => {
                  setSelected({
                    ...record,
                  });
                  setApproverModal(true);
                }}
                className="flex justify-center items-center text-gray-700 border rounded-md px-2 py-1 bg-blue-100 hover:bg-blue-200 cursor-pointer"
              >
                <FiEdit />
                <span className="ml-1">Make Approver</span>
              </button>
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <Manage
      title={"Users"}
      subtitle={"All"}
      description={"List of all users"}
      actions={<CreateWrapper refetch={handleFetchUsers} />}
    >
      <div className="grid gap-x-0 grid-cols-12">
        <div className="col-span-12 sm:col-span-12 md:col-span-12">
          <Table
            columns={columns}
            dataSource={users}
            size="small"
            pagination={{ pageSize: 20 }}
            scroll={{ y: 525 }}
            loading={isLoading}
            rowKey="id"
          />

          <UpdateUser
            selected={selected}
            isVisible={updateModal}
            setUpdateModal={setUpdateModal}
            refetch={handleFetchUsers}
          />

          <UpdateApr
            selected={selected}
            isVisible={approverModal}
            setVisible={setApproverModal}
            refetch={handleFetchUsers}
          />

          <UpdateCountry
            selected={selected}
            isVisible={countryModal}
            setVisible={setCountryModal}
            refetch={handleFetchUsers}
          />

          <UpdateScope
            selected={selected}
            isVisible={scopeModal}
            setVisible={setScopeModal}
            refetch={handleFetchUsers}
          />

          <EditUser
            selected={selected}
            isVisible={editModal}
            setVisible={setEditModal}
            refetch={handleFetchUsers}
          />

          <DeleteUser
            selected={selected}
            isVisible={deleteModal}
            setVisible={setDeleteModal}
            refetch={handleFetchUsers}
          />
        </div>
      </div>
    </Manage>
  );
}
