import { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  LabelList,
  Bar,
  BarChart,
} from "recharts";
import claimsByMain from "../../../utils/claimsByMain";
import formatShort from "../../../utils/formatShort";

export default function MainChart({ claims }: any) {
  const [data, setData] = useState<any>([]);

  const handleFetchYearlyData = async () => {
    const data = claimsByMain({ claims });
    setData(data?.data);
  };
  const labelFormatter = (value: any) => {
    return formatShort(value);
  };

  useEffect(() => {
    handleFetchYearlyData();
  }, [claims]);

  return (
    <div style={{ width: "100%" }}>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 25, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name">
            <Label offset={0} position="insideBottom" />
          </XAxis>
          <YAxis label={{ value: "", angle: -90, position: "insideLeft" }} />
          <Bar dataKey="Amount" fill="#4145A8" barSize={20}>
            <LabelList
              dataKey="Amount"
              position="top"
              formatter={labelFormatter}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
