import { useState } from "react";
import UpdateDetails from "./UpdateDetails";
import UpdatePassword from "./UpdatePassword";
import { FiEdit } from "react-icons/fi";
import { RiLockPasswordLine } from "react-icons/ri";

export default function UpdateWrapper({ isLoading }: any) {
  const [detailsModal, setDetailsModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);

  return (
    <div className="flex">
      <button
        onClick={() => setDetailsModal(true)}
        className="rounded-md flex justify-center items-center border border-gray-200 px-2 py-1 focus:outline-none hover:bg-gray-200"
      >
        <FiEdit />
        <span className="ml-2">Details</span>
      </button>
      <button
        onClick={() => setPasswordModal(true)}
        className="rounded-md flex justify-center items-center border ml-2 border-gray-200 px-2 py-1 focus:outline-none hover:bg-gray-200"
      >
        <RiLockPasswordLine />
        <span className="ml-2">Password</span>
      </button>
      <UpdateDetails
        isVisible={detailsModal}
        isLoading={isLoading}
        setVisible={setDetailsModal}
      />

      <UpdatePassword
        isVisible={passwordModal}
        isLoading={isLoading}
        setVisible={setPasswordModal}
      />
    </div>
  );
}
