import { Button, Modal, Form, Row, Col, Select, Input } from "antd";
import { useEffect, useState } from "react";
import SweetAlert2 from "react-sweetalert2";
import { BsUpload } from "react-icons/bs";
import fetchDataClaim from "../../../services/fetchDataClaim";
import postDataClaim from "../../../services/postDataClaim";
import getBase64 from "../../../utils/getBase64";

const { Option } = Select;

export default function CreateItem({
  isVisible,
  selected,
  setVisible,
  refetch,
}: any) {
  const [isLoading, setLoading] = useState(false);
  const [constants, setConstants] = useState<any>([]);
  const [receipt, setReceipt] = useState<any>(null);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [swalProps, setSwalProps] = useState({});

  const [form] = Form.useForm();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const onSubmit = async () => {
    setLoading(true);
    const { category, description, amount } = await form.getFieldsValue();

    let base64,
      type,
      size = null;

    if (selectedFile) {
      base64 = await getBase64(selectedFile);
      type = selectedFile?.type?.slice(selectedFile?.type - 3);
      size = selectedFile?.size;
    }

    const data = {
      category,
      description,
      amount: amount,
      hasAttachment: false,
      attachment: base64,
      filetype: type,
      filesize: size,
      hasReceipt: selectedFile ? true : false,
    };

    const uri = `claim/${selected?._id}/item`;
    const response = await postDataClaim(data, uri);

    if (response.success) {
      setSwalProps({
        show: true,
        title: "Added",
        text: "Expense Item Added Successfully",
        icon: "success",
      });
      await refetch();
      form.resetFields();
      hideModal();
      setIsSelected(false);
      setSelectedFile(null);
      setLoading(false);
      setSwalProps({});
    } else {
      setSwalProps({
        show: true,
        title: "Error",
        text: "Sorry, Something went wrong",
        icon: "error",
      });
      hideModal();
      setLoading(false);
      setIsSelected(false);
      setSelectedFile(null);
      setSwalProps({});
    }
    setLoading(false);
  };

  const hideModal = () => {
    setVisible(false);
    setLoading(false);
  };

  const handleFetchContants = async () => {
    const uri = `claim/constants/all`;
    const response = await fetchDataClaim({ uri });
    setConstants(response?.payload);
  };

  const categoryOptions = constants?.categories?.map(
    (category: any, index: number) => (
      <Option value={category} key={index}>
        {category}
      </Option>
    )
  );

  useEffect(() => {
    form.resetFields();
    handleFetchContants();
  }, []);

  return (
    <Modal
      open={isVisible}
      title="Add Expense Item"
      onCancel={hideModal}
      footer={[
        <Button key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="createItem"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Add
        </Button>,
      ]}
    >
      <Form
        id="createItem"
        form={form}
        layout="vertical"
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="category"
              label="Expense Category"
              rules={[
                {
                  required: true,
                  message: "Please select category",
                },
              ]}
            >
              <Select placeholder="Expense Category">{categoryOptions}</Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please enter description",
                },
              ]}
            >
              <Input placeholder="Description" className="w-full" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="amount"
              label="Expense Amount"
              rules={[
                {
                  required: true,
                  message: "Please enter amount",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Expense Amount"
                className="w-full"
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex flex-col justify-center items-center bg-gray-100 h-32 rounded-md border">
          {receipt ? (
            <div className="flex flex-col justify-center items-center">
              <div className="mb-1">
                <BsUpload size={20} />
              </div>
              <div className="mt-1">Upload supporting document (if any)</div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <Form.Item
                name="file"
                label="Upload supporting document ( Optional )"
                rules={[
                  {
                    required: false,
                    message: "Please select file",
                  },
                ]}
              >
                <Input
                  type="file"
                  // accept="image/png, image/gif, image/jpeg"
                  placeholder="Document"
                  onChange={changeHandler}
                />
              </Form.Item>
            </div>
          )}
        </div>
      </Form>
      <SweetAlert2 {...swalProps} />
    </Modal>
  );
}
