import { Button, Modal, Form, Row, Col, Select, Input } from "antd";
import { message } from "antd";
import { useEffect, useState } from "react";
import fetchDataTrace from "../../../../services/fetchDataTrace";
import postDataClaim from "../../../../services/postDataClaim";

const { Option } = Select;

export default function CreateCurrency({
  isVisible,
  isLoading,
  setVisible,
  refetch,
}: any) {
  const [form] = Form.useForm();
  const [users, setUsers] = useState<any>([]);

  const onSubmit = async () => {
    const {name, rate} = await form.getFieldsValue();
    const data = {
      name,
      rate
    };
    const uri = `claim/controls/currencies`;

    const response = await postDataClaim(data, uri);
    if (response.success) {
      message.success("Created Successfully");
      await refetch();
      form.resetFields();
      setVisible(false);
    } else {
      message.error(response.message);
    }
  };

  const handleFetchUsers = async () => {
    const uri = `users`;
    const response = await fetchDataTrace({ uri });
    setUsers(response?.payload);
  };

  const userOptions = users.map((user: any, index: number) => (
    <Option value={user?._id} key={index}>
      {user?.firstname + " " + user?.lastname}
    </Option>
  ));

  useEffect(() => {
    form.resetFields();
    handleFetchUsers();
  }, []);

  return (
    <Modal
      open={isVisible}
      title="Create Currency"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="AddCurrency"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="AddCurrency"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Currency Name"
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input type="text" placeholder="Name"/>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="rate"
              label="Currency Rate"
              rules={[
                {
                  required: true,
                  message: "Please enter rate",
                },
              ]}
            >
              <Input type="text" placeholder="Rate"/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
