import React from "react";

export default function Divider({ title }: any) {
  return (
    <div
      style={{
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
      }}
      className="flex justify-center items-center w-full"
    >
      <div
        style={{
          height: 0.5,
          width: 100,
          backgroundColor: "#323232",
        }}
        className="mx-2"
      ></div>
      <div
        style={{
          fontSize: 10,
          marginTop: -1,
          fontFamily: "toyota-regular",
        }}
      >
        {title}
      </div>
      <div
        style={{
          height: 0.5,
          width: 100,
          backgroundColor: "#323232",
        }}
        className="mx-2"
      ></div>
    </div>
  );
}
