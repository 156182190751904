import { Button, Modal, Form, Row, Col, Select, message } from "antd";
import { useEffect, useState } from "react";
import patchDataClaim from "../../../../services/patchDataClaim";

const { Option } = Select;

export default function UpdateScope({
  isVisible,
  selected,
  setVisible,
  refetch,
}: any) {
  const [isLoading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    const values = await form.getFieldsValue();

    const { aprScope } = values;
    if (!aprScope) {
      message.error("Please select scope");
      return;
    }

    const data = { aprScope };
    const uri = `claim/claimers/${selected?._id}/update/scope`;
    const response = await patchDataClaim(data, uri);
    if (response.success) {
      message.success("Updated Successfully");
      await refetch();
      form.resetFields();
      hideModal();
    } else {
      message.error(response.message);
    }
  };

  const hideModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    form.setFieldsValue({aprScope: selected?.aprScope});
  }, [selected]);

  return (
    <Modal
      open={isVisible}
      title="Update Country"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="udateScope"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="udateScope"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="aprScope"
              label="Scope"
              initialValue={selected?.aprScope}
              rules={[
                {
                  required: true,
                  message: "Please select scope",
                },
              ]}
            >
              <Select
                defaultValue={selected?.aprScope}
                placeholder="Scope"
              >
                <Option value={"TZ"}>TZ</Option>
                <Option value={"OT"}>OT</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
