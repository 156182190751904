import { Button, Drawer, Form, Space } from "antd";
import moment from "moment";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsDownload, BsEye, BsEyeFill } from "react-icons/bs";
import NoResults from "../../../components/shared/NoResults";
import formatMoney from "../../../utils/formatMoney";
import formatSize from "../../../utils/formatSize";
import { saveAs } from "file-saver";
import photo from "../../../assets/images/photo.png";
import base64ToFile from "../../../utils/base64ToFile";
export default function ItemsPanel({ setVisible, isVisible, selected }: any) {
  const [preview, setPreview] = useState<any>("");

  const getTotal = (items: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + item?.amount;
    });

    return total;
  };

  const attachments = selected?.items?.filter(
    (item: any) => item?.hasReceipt === true
  );

  const startsWith = (str: any, word: any) => {
    return str.lastIndexOf(word, 0) === 0;
  };

  const downloadBase64Data = (base64String: any, fileName: any) => {
    let file = base64ToFile(base64String, fileName);
    saveAs(file, fileName);
  };

  return (
    <Drawer
      title={`${selected?.claimer?.name}- ${moment(selected?.claim_date).format(
        "Do MMM, YYYY"
      )}`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      visible={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="font-toyota">
        <div className="flex py-1 border-b border-gray-300">
          <div className="mr-2 w-10 text-center font-bold">S/N.</div>
          <div className="flex justify-between items-center flex-1">
            <div className="flex items-center">
              <div className="w-52 truncate font-bold">Description</div>
              <div className="w-64 truncate text-left font-bold">
                Expense Type
              </div>
            </div>
            <div className="font-bold w-full">
              <div className="flex">
                <div className="mr-2 text-left flex-1"></div>
                <div className="truncate flex justify-end">Amount</div>
              </div>
            </div>
          </div>
        </div>
        {selected?.items?.map((item: any, index: number) => (
          <div className="flex py-1 border-b border-gray-300" key={index}>
            <div className="mr-2 w-10 text-center">{index + 1}.</div>
            <div className="flex justify-between items-center flex-1">
              <div className="flex items-center">
                <div className="w-52">
                  {item?.description === null ? "-" : item?.description}
                </div>
                <div className="w-64 truncate">
                  {item?.category === null ? "-" : item?.category}
                </div>
              </div>
              <div className="w-full">
                <div className="flex">
                  <div className="mr-2 text-left flex-1"></div>
                  <div className="truncate ml-2 px-2 py-1 flex justify-center items-center">
                    <span className="ml-2"> {formatMoney(item?.amount)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="flex justify-between items-start border-t-2 border-b-2 p-2">
          <div className="ml-2">Total </div>
          <div className="font-bold">
            {formatMoney(getTotal(selected?.items))}
          </div>
        </div>

        <div className="mt-8">
          <div className=" border-b py-4">Attachments</div>
          <div className="">
            {attachments?.length === 0 ? (
              <div className="mt-12">
                <NoResults title={"No Attachments"} />
              </div>
            ) : null}
            {attachments?.map((claim: any, index: number) => (
              <div className="flex justify-between mt-2" key={index}>
                <div className="flex">
                  <div className="mr-2">
                    <img src={photo} alt="Logo" height={15} width={35} />
                  </div>
                  <div className="flex flex-col justify-center">
                    <div className="">{claim?.category}</div>
                    <div className="text-xs">{formatSize(claim?.filesize)}</div>
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  {preview?._id === claim?._id ? (
                    <button onClick={() => setPreview("")} className="">
                      <BsEyeFill color="#16a34a" />
                    </button>
                  ) : (
                    <button onClick={() => setPreview(claim)} className="">
                      <BsEye />
                    </button>
                  )}
                  <button
                    onClick={() => {
                      downloadBase64Data(claim?.attachment, "Receipt");
                      setPreview(claim);
                    }}
                    className="ml-4 hover:text-green-600"
                  >
                    <BsDownload />
                  </button>
                </div>
              </div>
            ))}
            {preview ? (
              <div className="border p-1 mt-4">
                {startsWith(preview?.attachment, "data:image") ? (
                  <img src={`${preview?.attachment}`} />
                ) : (
                  <img src={`data:image/png;base64,${preview?.attachment}`} />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Drawer>
  );
}
