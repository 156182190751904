import { Button, Modal, Form, Row, Col, Select } from "antd";
import { message } from "antd";
import { useEffect, useState } from "react";
import fetchDataTrace from "../../../../services/fetchDataTrace";
import postDataClaim from "../../../../services/postDataClaim";

const { Option } = Select;

export default function CreateUser({
  isVisible,
  isLoading,
  setVisible,
  refetch,
}: any) {
  const [form] = Form.useForm();
  const [users, setUsers] = useState<any>([]);

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const { id } = values;
    const user = users?.filter((user: any) => user?._id == id);
    const data = {
      name: `${user[0]?.firstname} ${user[0]?.lastname}`,
      email: user[0]?.email,
      mobile: user[0]?.mobile,
      mobile2: user[0]?.mobile2,
    };
    const uri = `claim/claimers/create`;

    const response = await postDataClaim(data, uri);
    if (response.success) {
      message.success("Registed Successfully");
      await refetch();
      form.resetFields();
      setVisible(false);
    } else {
      message.error(response.message);
    }
  };

  const handleFetchUsers = async () => {
    const uri = `users`;
    const response = await fetchDataTrace({ uri });
    setUsers(response?.payload);
  };

  const userOptions = users.map((user: any, index: number) => (
    <Option value={user?._id} key={index}>
      {user?.firstname + " " + user?.lastname}
    </Option>
  ));

  useEffect(() => {
    form.resetFields();
    handleFetchUsers();
  }, []);

  return (
    <Modal
      visible={isVisible}
      title="Create Account"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="AddUser"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="AddUser"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="id"
              label="Select User From Main Database"
              rules={[
                {
                  required: true,
                  message: "Please select user",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select user"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {userOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
