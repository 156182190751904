import { useEffect, useState } from "react";
import { Button, Modal, Form, message } from "antd";
import formatMoney from "../../../utils/formatMoney";
import deleteDataClaim from "../../../services/deleteDataClaim";
import Moment from "react-moment";
import SweetAlert2 from "react-sweetalert2";

import { useNavigate } from "react-router";

export default function DeleteClaim({
  selected,
  isVisible,
  setVisible,
  refetch,
}: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [swalProps, setSwalProps] = useState({});

  useEffect(() => {}, [selected]);

  const getTotal = (items: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + item?.amount;
    });

    return total;
  };
  const onSubmit = async () => {
    setLoading(true);
    const uri = `claim/${selected?._id}`;
    const data = {};

    // return;
    const response = await deleteDataClaim(data, uri);

    if (response.success) {
      // message.success("Deleted Successfully");
      await refetch();
      setSwalProps({
        show: true,
        title: "Deleted",
        text: "Expense Claim Deleted Successfully",
        icon: "success",
      });
      navigate("/claims");
      setVisible(false);
      setLoading(false);
    } else {
      message.error("Something went wrong ");
      setLoading(false);
    }
  };

  const hideModal = async () => {
    setVisible(false);
    setLoading(false);
  };

  return (
    <Modal
      visible={isVisible}
      title="Delete Claim"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="DeleteClaim"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Delete
        </Button>,
      ]}
    >
      <Form id="DeleteClaim" form={form} onFinish={onSubmit}>
        <div>
          <p className="text-center">
            Are you sure you want to
            <span className="text-red-500 font-bold mx-1">delete</span>
            this Expense Claim
          </p>
          <div className="pb-4 flex flex-col justify-center items-center">
            <div className="flex text-black text-lg mb-2">
              {selected?.claimer?.name}
            </div>
            <div className="">
              <Moment format="Do MMM, YYYY">{selected?.createdAt}</Moment>
            </div>
            <div className="flex text-xl mt-4 font-bold">
              {formatMoney(getTotal(selected?.items))} /=
            </div>
          </div>
        </div>
      </Form>
      <SweetAlert2 {...swalProps} />
    </Modal>
  );
}
