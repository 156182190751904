import formatMoney from "../../utils/formatMoney";

export default function DocumentLine({ item }: any) {
  return (
    <div className="grid grid-cols-12 border-t border-black items-center text-gray-700">
      <div className="col-span-4 px-2  border-r border-black  text-left">
        {item?.description}
      </div>
      <div className="col-span-4 px-2   border-r border-black truncate">
        {item?.category}
      </div>
      <div className="col-span-2 px-2  border-r border-black  text-right">
        {formatMoney(item?.amount ? item?.amount : 0)}
      </div>
      <div className="col-span-2 px-2 text-right">
        {item?.hasReceipt ? "Yes" : "No"}
      </div>
    </div>
  );
}
