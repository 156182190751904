import { Button, Drawer, Form, Space, Input } from "antd";
import moment from "moment";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiExport } from "react-icons/bi";
import { BsEye } from "react-icons/bs";
import { RiFilterOffLine } from "react-icons/ri";
import { ExportToExcel } from "../../../utils/exportExcel";
import formatMoney from "../../../utils/formatMoney";
import ItemsPanel from "./ItemsPanel";
const { Search } = Input;
export default function MainPanel({ setVisible, isVisible, selected }: any) {
  const [search, setSearch] = useState("");
  const [showItems, setShowItems] = useState<boolean>(false);
  const [claim, setClaim] = useState<any>();

  const getTotal = (items: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + item?.amount;
    });
    return total;
  };

  const [form] = Form.useForm();

  const handleClearSearch = () => {
    setSearch("");
    form.resetFields();
  };

  return (
    <Drawer
      title={`Expense claims`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      visible={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="font-toyota">
        <div className="flex justify-between items-center border-b border-gray-300 mb-2 pb-2 -mt-4">
          <div className="flex">
            <Form layout="inline" form={form}>
              <Form.Item name="registration">
                <Search
                  placeholder="Search by month"
                  allowClear
                  onChange={(val: any) => {
                    setSearch(val.target.value);
                  }}
                  onSearch={(value: any) => {
                    setSearch(value);
                  }}
                />
              </Form.Item>

              <Button className="" onClick={() => handleClearSearch()}>
                <RiFilterOffLine color="#6b7280" />
              </Button>
            </Form>
          </div>
          <div className="flex ">
            <button
              color="#E50000"
              className="flex justify-center items-center border p-1 hover:bg-green-100"
            >
              <BiExport size={24} />
              <span
                className="ml-2"
                onClick={() =>
                  ExportToExcel(
                    selected?.data?.map((item: any, index: number) => {
                      return {
                        "S/N": index + 1,
                        Staff:
                          item?.claimer?.name === null
                            ? "-"
                            : item?.claimer?.name,
                        Requested:
                          item?.createdAt === null
                            ? "-"
                            : moment(item?.createdAt).format("DD/MM/YYYY"),
                        "Expense Date":
                          item?.claim_date === null
                            ? "-"
                            : moment(item?.claim_date).format("DD/MM/YYYY"),
                        "Expense Month":
                          item?.expense_month === null
                            ? "-"
                            : item?.expense_month,
                        "Total Amount": getTotal(item?.items),
                        Status: item?.status,
                      };
                    }),
                    "Claims.xlsx"
                  )
                }
              >
                Export To Excel
              </span>
            </button>
          </div>
        </div>
        <div className="flex py-1 border-b border-gray-300">
          <div className="mr-2 w-10 text-center font-bold">S/N.</div>
          <div className="flex justify-between items-center flex-1">
            <div className="flex items-center">
              <div className="w-52 truncate font-bold">Staff</div>
              <div className="w-36 truncate text-left font-bold">Date</div>
              <div className="w-36 truncate font-bold">Month</div>
            </div>
            <div className="font-bold w-full">
              <div className="flex">
                <div className="mr-2 text-left flex-1">Amount</div>
                <div className="truncate flex justify-end">Details</div>
              </div>
            </div>
          </div>
        </div>
        {selected?.data
          ?.filter((cla: any) => cla?.expense_month?.includes(search))
          .map((item: any, index: number) => (
            <div className="flex py-1 border-b border-gray-300" key={index}>
              <div className="mr-2 w-10 text-center">{index + 1}.</div>
              <div className="flex justify-between items-center flex-1">
                <div className="flex items-center">
                  <div className="w-52">
                    {item?.claimer?.name === null ? "-" : item?.claimer?.name}
                  </div>
                  <div className="w-36 truncate">
                    {item?.claim_date === null
                      ? "-"
                      : moment(item?.claim_date).format("Do MMM, YYYY")}
                  </div>
                  <div className="w-36 truncate flex">
                    {item?.expense_month}
                  </div>
                </div>
                <div className="w-full">
                  <div className="flex">
                    <div className="mr-2 text-left flex-1">
                      {formatMoney(getTotal(item?.items))}
                    </div>
                    <button
                      onClick={() => {
                        setShowItems(true);
                        setClaim(item);
                      }}
                      className="truncate ml-2 border px-2 py-1 flex justify-center items-center hover:bg-blue-50 hover:cursor-pointer"
                    >
                      <BsEye /> <span className="ml-2">Details</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <div className="flex justify-between items-start border-t-2 border-b-2 py-2">
          <div className="ml-2">Total</div>
          <div className="font-bold">
            {formatMoney(selected?.total ? selected.total : 0)}
          </div>
        </div>
        <ItemsPanel
          isVisible={showItems}
          setVisible={setShowItems}
          selected={claim}
        />
      </div>
    </Drawer>
  );
}
