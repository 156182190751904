import { useContext, useEffect, useState } from "react";
import { Button, Modal, Form, Input } from "antd";
import formatMoney from "../../../utils/formatMoney";
import { BsCheck2 } from "react-icons/bs";
import postDataClaim from "../../../services/postDataClaim";
import { AuthContext } from "../../../contexts/AuthContext";
import SweetAlert2 from "react-sweetalert2";

export default function ApproveClaim({
  selected,
  isVisible,
  setVisible,
  refetch,
  claim,
  total,
}: any) {
  const { user } = useContext(AuthContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [swalProps, setSwalProps] = useState({});
  const [approval, setApproval] = useState<any>(null);
  const [form] = Form.useForm();

  const approvals = [
    { id: 1, name: "Accept" },
    { id: -1, name: "Decline" },
  ];

  useEffect(() => {}, [selected]);

  const onSubmit = async () => {
    setLoading(true);
    const { remarks } = form.getFieldsValue();

    const data = { remarks, approver_email: user?.email };
    const action = approval.id === -1 ? "reject" : "approve";

    const uri = `claim/${claim?._id}/${action}`;
    const response = await postDataClaim(data, uri);

    if (response.success) {
      const vedict = approval.id === -1 ? "Rejected" : "Approved";
      setSwalProps({
        show: true,
        title: `${vedict}`,
        text: `Expense Claim ${vedict} Successfully`,
        icon: "success",
      });
      await refetch();
      setVisible(false);
      setLoading(false);
      setSwalProps({});
    } else {
      setSwalProps({
        show: true,
        title: "Error",
        text: "Sorry, Something went wrong",
        icon: "error",
      });
      setVisible(false);
      setLoading(false);
      setSwalProps({});
    }
    setVisible(false);
    setLoading(false);
  };

  const hideModal = async () => {
    setVisible(false);
    setLoading(false);
  };

  return (
    <Modal
      open={isVisible}
      title="Approve Claim"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="ApproveClaim"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
        >
          Submit
        </Button>,
      ]}
    >
      <Form id="ApproveClaim" form={form} onFinish={onSubmit} layout="vertical">
        <div className="px-4">
          <div className="flex justify-between items-center mb-4">
            {approvals?.map((item: any) => (
              <div
                onClick={() => setApproval(item)}
                className="flex items-center hover:bg-gray-100 hover:cursor-pointer py-2"
              >
                {approval?.id === item?.id ? (
                  <div className="flex justify-center items-center h-8 w-8 border bg-green-100">
                    <BsCheck2 />
                  </div>
                ) : (
                  <div className="h-8 w-8 border"></div>
                )}
                <div className="ml-2">{item?.name}</div>
              </div>
            ))}
          </div>
          {approval?.id === -1 ? (
            <Form.Item
              name="remarks"
              label="Remarks"
              rules={[
                {
                  required: true,
                  message: "Please enter remarks",
                },
              ]}
            >
              <Input placeholder="Remarks" className="w-full" />
            </Form.Item>
          ) : (
            <Form.Item
              name="remarks"
              label="Remarks ( Optional )"
              rules={[
                {
                  required: false,
                  message: "Please enter remarks",
                },
              ]}
            >
              <Input placeholder="Remarks" className="w-full" />
            </Form.Item>
          )}

          <div className="pb-4 flex flex-col justify-center items-center">
            <div className="flex text-black text-lg">Total Amount</div>
            <div className="flex text-xl font-bold">{formatMoney(total)}</div>
          </div>
        </div>
      </Form>
      <SweetAlert2 {...swalProps} />
    </Modal>
  );
}
