import { Outlet } from "react-router";
import { GoHome } from "react-icons/go";
import { ImSafari } from "react-icons/im";
import { IoIosCog } from "react-icons/io";
import AdminLink from "../components/shared/AdminLink";
import Header from "../components/layouts/Header";
import { BsAward } from "react-icons/bs";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

export default function MainLayout() {
  const { user } = useContext(AuthContext);
  const menus = [
    {
      id: 1,
      isActive: true,
      to: "/dashboard",
      name: "Dashboard",
      icon: <GoHome size={28} color={"#FFFFFF"} />,
    },
    {
      id: 2,
      isActive: false,
      to: "/claims",
      name: "Claims",
      icon: <BsAward size={28} color={"#FFFFFF"} />,
    },
    {
      id: 3,
      isActive: false,
      to: "/safaris",
      name: "Safaris",
      icon: <ImSafari size={22} color={"#FFFFFF"} />,
    },
  ];

  const admins = [
    {
      id: 4,
      isActive: false,
      to: "/manage/users",
      name: "Manage",
      icon: <IoIosCog size={28} color={"#FFFFFF"} />,
    },
  ];
  return (
    <div className="flex bg-gray-100 font-toyota">
      <div className="w-full">
        <Header />
        <div className="flex">
          <div className="flex flex-col min-h-screen bg-toyota w-24">
            <div className="mt-8">
              {menus.map((menu: any, index: number) => (
                <AdminLink menu={menu} key={index}>
                  {menu.name}
                </AdminLink>
              ))}
              {user?.accessLevel === 9 ? (
                <>
                  {admins.map((menu: any, index: number) => (
                    <AdminLink menu={menu} key={index}>
                      {menu.name}
                    </AdminLink>
                  ))}
                </>
              ) : null}
            </div>
          </div>
          <div className="flex-1">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
