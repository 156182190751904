import { slice } from "lodash";
import DocumentLine from "./DocumentLine";

export default function DocumentLines({ items }: any) {
  const data = new Array(22).fill({ id: 1 });
  let few = slice(data, 0, 22 - items?.length);
  return (
    <div className="border border-black  mt-2">
      <div className="grid grid-cols-12 border-b  border-black">
        <div className="col-span-4 py-1 px-2 font-bold border-r border-black  text-left truncate">
          Description
        </div>
        <div className="col-span-4 py-1 px-2  font-bold border-r border-black  text-left">
          Claim Type
        </div>
        <div className="col-span-2 py-1 px-2  font-bold border-r border-black  text-right">
          Cost
        </div>
        <div className="col-span-2 py-1 px-2  font-bold text-right">
          Receipt
        </div>
      </div>
      <div className="">
        {items?.map((item: any, i: any) => (
          <DocumentLine
            style={{ color: "red" }}
            key={i + item._id}
            index={i}
            item={item}
          />
        ))}
      </div>
      {few.map((item: any, index: number) => (
        <div
          className="grid grid-cols-12 items-center border-t border-black"
          key={index}
        >
          <div className="col-span-4 px-1 border-r text-white  border-black  text-center">
            -
          </div>
          <div className="col-span-4 px-1 text-white border-r border-black text-right">
            -
          </div>
          <div className="col-span-2 px-1 text-white border-r border-black text-right">
            -
          </div>
          <div className="col-span-2 px-1 text-white text-right">-</div>
        </div>
      ))}
    </div>
  );
}
