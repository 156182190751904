import { useEffect, useState } from "react";
import { Button, Modal, Form } from "antd";
import SweetAlert2 from "react-sweetalert2";
import formatMoney from "../../../utils/formatMoney";
import deleteDataClaim from "../../../services/deleteDataClaim";

export default function DeleteItem({
  selected,
  isVisible,
  setVisible,
  refetch,
  claim,
}: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [swalProps, setSwalProps] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {}, [selected]);

  const onSubmit = async () => {
    setLoading(true);
    const uri = `claim/${claim?._id}/item/${selected?._id}`;
    const data = {};

    const response = await deleteDataClaim(data, uri);

    if (response.success) {
      setSwalProps({
        show: true,
        title: "Deleted",
        text: "Expense Item Added Successfully",
        icon: "success",
      });
      await refetch();
      setVisible(false);
      setSwalProps({});
    } else {
      setSwalProps({
        show: true,
        title: "Error",
        text: "Sorry, Something went wrong",
        icon: "error",
      });
      setVisible(false);
      setSwalProps({});
    }
    setVisible(false);
    setLoading(false);
  };

  const hideModal = async () => {
    setVisible(false);
  };

  return (
    <Modal
      open={isVisible}
      title="Delete Item"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="DeleteItem"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Delete
        </Button>,
      ]}
    >
      <Form id="DeleteItem" form={form} onFinish={onSubmit}>
        <div>
          <p className="text-center font-bold">
            Are you sure you want to
            <span className="text-red-500 font-bold mx-1">delete</span>
            this item
          </p>
          <div className="pb-4 flex flex-col justify-center items-center">
            <div className="flex text-black text-lg">{selected?.category}</div>
            <div className="flex text-xl mt-4 font-bold">
              {formatMoney(selected?.amount)}
            </div>
          </div>
        </div>
      </Form>
      <SweetAlert2 {...swalProps} />
    </Modal>
  );
}
