import { Button, Drawer, Space, Spin } from "antd";
import { AiOutlineClose, AiOutlinePrinter } from "react-icons/ai";
import DocumentWrapper from "../../../components/docum/DocumentWrapper";
import ReactToPrint from "react-to-print";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import splitChunks from "../../../utils/splitChunks";
import { transitions } from "@mantine/core/lib/components/Transition/transitions";

export default function Document({
  isVisible,
  setVisible,
  selected,
  claim,
}: any) {
  const docRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [statements, setStatements] = useState<any>([]);
  const [allTransaction, setTransactions] = useState<any>([]);

  const handleFetchStatements = async () => {
    const data = claim?.items;
    const chunks = splitChunks(data, 17);
    setTransactions(data);
    setStatements(chunks);
  };

  const hideModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    handleFetchStatements();
  }, [selected]);

  const getTotalDue = () => {
    let total = 0;

    allTransaction.forEach((item: any) => {
      total = total + item.outstanding;
    });
    return total;
  };

  return (
    <Drawer
      title={`Expense Claim`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      visible={isVisible}
      closable={false}
      extra={
        <Space className="flex justify-center items-center">
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div>
        <div className="flex flex-row justify-center items-center">
          <div className="flex justify-between items-center border-b h-12 w-full -mt-6">
            <div className=""></div>
            <div className="border border-gray-300 p-2 rounded-sm">
              <ReactToPrint
                trigger={() => {
                  return (
                    <button className="flex justify-center items-center focus:outline-none hover:text-red-500 mr-2 text-sm">
                      <AiOutlinePrinter className="mr-2" /> Print
                    </button>
                  );
                }}
                content={() => docRef.current}
                documentTitle={`Claim`}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row bg-whitish  justify-center items-start overflow-auto">
          <div className="bg-white px-4">
            {isLoading ? (
              <div className="">
                <Spin />
              </div>
            ) : (
              <div className="">
                <div className="book" ref={docRef}>
                  {statements?.map((page: any, index: number) => (
                    <div className="page" key={index}>
                      <div className="subpage">
                        <DocumentWrapper
                          statements={page}
                          selected={selected}
                          pageno={index + 1}
                          pages={statements.length}
                          getTotalDue={getTotalDue}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
}
