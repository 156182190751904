export default function DocumentLogo({ claim }: any) {
  const isToyota = claim?.company?.includes("Toyota");
  return (
    <div className="py-4 flex flex-row justify-between items-center text-xs">
      <div className="flex flex-col w-full sm:w-2/4">
        <div
          className={`px-2 font-bold text-lg uppercase  ${
            isToyota ? "text-red-600" : "text-toyota"
          }`}
        >
          {claim?.company}
        </div>
        <div className="px-2 flex flex-row">
          <div className="flex-col">
            <div className="">{claim?.address1}</div>
            <div className="">{claim?.address2}</div>
            <div className="">{claim?.address3}</div>
            <div className="">{claim?.address4}</div>
          </div>
        </div>
        {/* <div className="px-2 flex flex-row items-center">
          <div className="">T: (255) 22 2866815-9</div>
          <div className="ml-2">F: (255) 22 2866814</div>
        </div> */}
      </div>
      <div className="flex justify-end w-full sm:w-2/4">
        <div className="flex flex-col justify-center items-center">
          <img src={claim?.logo} width="95" alt="" />
          <div>{claim?.website}</div>
        </div>
      </div>
    </div>
  );
}
