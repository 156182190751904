import DocumentLines from "./DocumentLines";
import DocumentLogo from "./DocumentLogo";
import formatMoney from "../../utils/formatMoney";
import DocumentInfo from "./DocumentInfo";
import DocumentEdits from "./DocumentEdits";
import jitesh from "../../assets/images/jitesh.png";
import farish from "../../assets/images/farishta.png";
import jasmine from "../../assets/images/jkarimjee.jpg";

export default function DocumentWrapper({
  claim,
  selected,
  setSelected,
  isEditable,
  updateItemModal,
  setUpdateItemModal,
  deleteItemModal,
  setDeleteItemModal,
}: any) {
  const getTotal = (items: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + item?.amount;
    });
    return total;
  };

  const signatures: any = {
    "ma@toyota.co.tz": jitesh,
    "farishta.kohli@karimjee.com": farish,
    "jasmine@karimjee.com": jasmine,
    "jkarimjee@toyota.co.tz": jasmine,
  };

  const sign = signatures[claim?.approver?.email];

  return (
    <div
      style={{ fontSize: 10 }}
      className="flex justify-center "
      id="invoice-doc"
    >
      <div className="w-full">
        <DocumentLogo claim={claim} />
        <DocumentInfo claim={claim} />
        <div className="flex justify-center items-center mt-8">
          EXPENSE CLAIM
        </div>
        {isEditable ? (
          <DocumentEdits
            items={claim?.items}
            selected={selected}
            setSelected={setSelected}
            currencyFormatter={formatMoney}
            updateItemModal={updateItemModal}
            setUpdateItemModal={setUpdateItemModal}
            deleteItemModal={deleteItemModal}
            setDeleteItemModal={setDeleteItemModal}
          />
        ) : (
          <DocumentLines items={claim?.items} currencyFormatter={formatMoney} />
        )}

        {isEditable ? (
          <div className="border border-black ">
            <div className="grid grid-cols-12 border-t  border-black  bg-white">
              <div className="col-span-8 py-1 px-2 font-bold border-r border-black  text-left">
                Total
              </div>
              <div className="col-span-2 py-1 px-2 font-bold border-r border-black text-right">
                {formatMoney(getTotal(claim?.items))}
              </div>
              <div className="col-span-1 border-r border-black py-1 px-2"></div>
              <div className="col-span-1 py-1 px-2"></div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-12 border-t  border-black  bg-white">
            <div className="col-span-5  border-r border-black"></div>
            <div className="col-span-3 py-1 px-2 font-bold border-r border-b border-black text-left">
              Total
            </div>
            <div className="col-span-2 py-1 px-2 font-bold text-right border-b border-r border-black">
              {formatMoney(getTotal(claim?.items))}
            </div>
            <div className="col-span-2 py-1 px-2"></div>
          </div>
        )}

        <div className="flex flex-col mt-20 text-xs">
          <div className="flex justify-between mb-10">
            <div className="w-52">
              <div className="">Employee Signature</div>
              <div className="border-b border-black h-12 mt-1"></div>
            </div>
            <div className="w-52">
              <div className="">Approved By (Signature)</div>
              <div className="border-b border-black h-12 mt-1">
                {claim?.status === "Approved" ? (
                  <img src={sign} width={70} alt="" />
                ) : null}
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-52">
              <div className="">Cashier</div>
              <div className="border-b border-black h-12 mt-1"></div>
            </div>
            <div className="w-52">
              <div className="">Received</div>
              <div className="border-b border-black h-12 mt-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
