const claimsByCateg = function ({ claims }: any) {
  let total = 0;
  let [electTotal, waterTotal, transTotal] = [0, 0, 0];
  let [mealTotal, enterTotal, vehicTotal] = [0, 0, 0];
  let [parkTotal, schTransTotal, schFeesTotal] = [0, 0, 0];
  let [clubTotal, teleTotal, maidTotal, internetTotal] = [0, 0, 0, 0];

  let electData: any = [];
  let waterData: any = [];
  let transData: any = [];
  let mealData: any = [];
  let enterData: any = [];
  let vehicData: any = [];
  let parkData: any = [];
  let schTransData: any = [];
  let schFeesData: any = [];
  let clubData: any = [];
  let teleData: any = [];
  let maidData: any = [];
  let internetData: any = [];

  claims.forEach((claim: any) => {
    claim?.items?.forEach((item: any) => {
      total = total + item?.amount;
      if (item?.category === "Electricity") {
        electData.push(claim);
        electTotal = electTotal + item?.amount;
      }
      if (item?.category === "Water") {
        waterData.push(claim);
        waterTotal = waterTotal + item?.amount;
      }
      if (item?.category === "Transport Expenses") {
        transData.push(claim);
        transTotal = transTotal + item?.amount;
      }
      if (item?.category === "Meal") {
        mealData.push(claim);
        mealTotal = mealTotal + item?.amount;
      }
      if (item?.category === "Entertainment") {
        enterData.push(claim);
        enterTotal = enterTotal + item?.amount;
      }
      if (item?.category === "Repairs & Maintenance (Vehicles)") {
        vehicData.push(claim);
        vehicTotal = vehicTotal + item?.amount;
      }
      if (item?.category === "Repairs & Maintenance (Parking/Security") {
        parkData.push(claim);
        parkTotal = parkTotal + item?.amount;
      }
      if (item?.category === "School Transport") {
        schTransData.push(claim);
        schTransTotal = schTransTotal + item?.amount;
      }
      if (item?.category === "Club Fees") {
        clubData.push(claim);
        clubTotal = clubTotal + item?.amount;
      }
      if (item?.category === "School Fees") {
        schFeesData.push(claim);
        schFeesTotal = schFeesTotal + item?.amount;
      }
      if (item?.category === "Telephone") {
        teleData.push(claim);
        teleTotal = teleTotal + item?.amount;
      }
      if (item?.category === "House Maid Wages") {
        maidData.push(claim);
        maidTotal = maidTotal + item?.amount;
      }
      if (item?.category === "Internet Charges") {
        internetData.push(claim);
        internetTotal = internetTotal + item?.amount;
      }
    });
  });

  const data = [
    { category: "Electricity", total: electTotal, data: electData },
    { category: "Water", total: waterTotal, data: waterData },
    { category: "Transport Expenses", total: transTotal, data: transData },
    { category: "Meal", total: mealTotal, data: mealData },
    { category: "Entertainment", total: enterTotal, data: enterData },
    {
      category: "Repairs & Maintenance (Vehicles)",
      total: vehicTotal,
      data: vehicData,
    },
    {
      category: "Repairs & Maintenance (Parking /Security)",
      total: parkTotal,
      data: parkData,
    },
    { category: "School Transport", total: schTransTotal, data: schTransData },
    { category: "Club Fees", total: clubTotal, data: clubData },
    { category: "School Fees", total: schFeesTotal, data: schFeesData },
    { category: "Telephone", total: teleTotal, data: teleData },
    { category: "House Maid Wages", total: maidTotal, data: maidData },
    { category: "Internet Charges", total: internetTotal, data: internetData },
  ];

  return { data, total };
};

export default claimsByCateg;
