import { useContext, useEffect, useRef, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { saveAs } from "file-saver";
import { MdAdd, MdApproval } from "react-icons/md";
import { useParams } from "react-router-dom";
import Wrapper from "../../components/shared/Wrapper";
import BackToolnav from "../../components/toolnav/BackToolnav";
import fetchDataClaim from "../../services/fetchDataClaim";
import CreateItem from "./components/CreateItem";
import pht_avatar from "../../assets/images/photo.png";
import pdf_avatar from "../../assets/images/pdf.png";
import { BsCheck2Square, BsDownload, BsEye, BsEyeFill } from "react-icons/bs";
import formatSize from "../../utils/formatSize";
import DeleteItem from "./components/DeleteItem";
import ApproveClaim from "./components/ApproveClaim";
import { claimStatus } from "../../utils/data";
import Moment from "react-moment";
import UpdateItem from "./components/UpdateItem";
import Document from "./components/Document";
import DocumentWrapper from "../../components/docum/DocumentWrapper";
import ReactToPrint from "react-to-print";
import {GrUpload} from "react-icons/gr"
import {
  AiOutlineCheckSquare,
  AiOutlineCloseSquare,
  AiOutlinePrinter,
} from "react-icons/ai";
import NoResults from "../../components/shared/NoResults";
import { AuthContext } from "../../contexts/AuthContext";
import base64ToFile from "../../utils/base64ToFile";
import SubmitClaim from "./components/SubmitClaim";
import { TbRefresh } from "react-icons/tb";
import LoadingClaim from "./components/LoadingClaim";
import { HiOutlineTrash } from "react-icons/hi";
import DeleteClaim from "./components/DeleteClaim";
import UploadFile from "./components/UploadFile";

export default function OneClaim() {
  const { user } = useContext(AuthContext);
  const [claim, setClaim] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [submitModal, setSubmitModal] = useState<boolean>(false);
  const [approveModal, setApproveModal] = useState<boolean>(false);
  const [updateItemModal, setUpdateItemModal] = useState<boolean>(false);
  const [deleteItemModal, setDeleteItemModal] = useState<boolean>(false);
  const [documentModal, setDocumentModal] = useState<boolean>(false);
  const [itemFileModal, setItemFileModal] = useState<boolean>(false);
  const [isEditable, setEditable] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const [preview, setPreview] = useState<any>("");
   const [imageData, setImageData] = useState<any>(null);

   const deleters = ["amahmoud@toyota.co.tz"];
   const docRef = useRef(null);

   let { claim_id } = useParams();

   const handleFetchClaim = async () => {
     setLoading(true);
     const uri = `claim/${claim_id}`;
     const response = await fetchDataClaim({ uri });
     setClaim(response?.payload);
     setLoading(false);
   };

   const handleReFetchClaim = async () => {
     const uri = `claim/${claim_id}`;
     const response = await fetchDataClaim({ uri });
     setClaim(response?.payload);
     setLoading(false);
   };

   const downloadBase64Data = (base64String: any, fileName: any) => {
     let file = base64ToFile(base64String, fileName);
     saveAs(file, fileName);
   };

   function bufferToExcel(buffer: any, fileName: any) {
     const arrayBuffer = new Uint8Array(buffer).buffer;
     const data = new Blob([arrayBuffer], {
       type: "application/octet-stream",
     });

     const blobUrl = window.URL.createObjectURL(data);
     const a = document.createElement("a");
     a.style.display = "none";
     a.href = blobUrl;
     a.download = fileName;
     document.body.appendChild(a);
     a.click();
     window.URL.revokeObjectURL(blobUrl);
   }

   const handleImageDownload = ({ url }: any) => {
     fetch(url)
       .then((response) => response.blob())
       .then((blob) => {
         setImageData(URL.createObjectURL(blob));
       })
       .catch((error) => console.error("Error downloading image:", error));
   };

   const handleDownload = ({ url }: any) => {
     const link = document.createElement("a");
     link.href = url;
     link.download = "image.jpg"; // You can specify the filename here
     link.click();
   };

   const downloadImage = ({ url }: any) => {
     // URL of the image you want to download
     const image_url = url;
     const file_extension = image_url.split(".").pop();

     const filename = `downloaded_image_${Date.now()}.${file_extension}`;

     // Create an anchor element to trigger the download
     const anchor = document.createElement("a");
     anchor.href = image_url;
     anchor.download = filename; // specify the filename

     // Simulate a click on the anchor element to initiate the download
     anchor.click();
   };

   const downloadImag = ({ url }: any) => {
     // URL of the image you want to download
     const image_url = url;

     // Send a GET request to the URL
     fetch(image_url)
       .then((response: any) => {
         if (response.ok) {
           // Extract the file extension from the URL (e.g., '.jpg')
           const file_extension = image_url.split(".").pop();

           // Generate a unique filename based on the current timestamp
           const filename = `downloaded_image_${Date.now()}.${file_extension}`;

           // Create an anchor element to trigger the download
           const anchor: any = document.createElement("a");
           anchor.href = window.URL.createObjectURL(
             new Blob([response.body], { type: "image/" + file_extension })
           );
           anchor.download = filename;
           anchor.style.display = "none";
           document.body.appendChild(anchor);

           // Trigger the download
           anchor.click();

           // Clean up
           window.URL.revokeObjectURL(anchor.href);
           document.body.removeChild(anchor);
         }
       })
       .catch((error) => {
         console.error("Failed to download the image", error);
       });
   };

   const getTotal = (items: any) => {
     let total = 0;
     items?.forEach((item: any) => {
       total = total + item?.amount;
     });
     return total;
   };

   const total = getTotal(claim?.items);

   const attachments = claim?.items?.filter(
     (item: any) => item?.hasReceipt === true
   );

   useEffect(() => {
     handleFetchClaim();
   }, []);

   if (isLoading || claim === undefined) {
     return <LoadingClaim />;
   }

   return (
     <>
       <BackToolnav />
       <Wrapper
         title={`${claim?.claimer?.name}`}
         subtitle={`${claim?.expense_month}`}
         description={
           <>
             <span
               style={{
                 background: claimStatus[claim?.status],
                 padding: 4,
                 borderWidth: 1,
                 marginRight: 4,
               }}
             >
               {claim?.status}
             </span>
             Created : {<Moment fromNow>{claim?.createdAt}</Moment>}
           </>
         }
         actions={
           <div className="flex justify-center items-center text-sm">
             {claim?.isFinished ? null : (
               <div className="flex">
                 <button
                   className="flex justify-center items-center border p-2 mr-2"
                   onClick={() => setCreateModal(true)}
                 >
                   <div className="">
                     <MdAdd />
                   </div>
                   <div className="ml-1">Add Item</div>
                 </button>

                 <button
                   onClick={() => handleFetchClaim()}
                   className={`flex justify-center items-center border p-2`}
                 >
                   <div className="">
                     <TbRefresh />
                   </div>
                   <div className="ml-1">Refresh</div>
                 </button>
                 <button
                   onClick={() => setEditable(!isEditable)}
                   className={`flex justify-center items-center border p-2 ml-2 ${
                     isEditable ? "bg-yellow-100" : ""
                   }`}
                 >
                   <div className="">
                     <FaRegEdit />
                   </div>
                   <div className="ml-1">Edit Mode</div>
                 </button>

                 {deleters.includes(user?.email) ? null : (
                   <button
                     className="flex justify-center items-center border p-2 ml-2"
                     onClick={() => setDeleteModal(true)}
                   >
                     <div className="">
                       <HiOutlineTrash color="red" />
                     </div>
                     <div className="ml-1">Delete</div>
                   </button>
                 )}

                 <button
                   onClick={() => setSubmitModal(true)}
                   className={`flex justify-center items-center border p-2 ml-2 bg-green-50
              `}
                 >
                   <div className="">
                     <BsCheck2Square />
                   </div>
                   <div className="ml-1">Submit</div>
                 </button>
               </div>
             )}

             {deleters.includes(user?.email) && (
               <>
                 {claim?.status === "Approved" ? null : (
                   <button
                     className="flex justify-center items-center border p-2 ml-2"
                     onClick={() => setDeleteModal(true)}
                   >
                     <div className="">
                       <HiOutlineTrash color="red" />
                     </div>
                     <div className="ml-1">Delete</div>
                   </button>
                 )}
               </>
             )}
             {user?.accessLevel === 5 ? (
               <button
                 className="flex justify-center items-center border p-2 ml-2"
                 onClick={() => setApproveModal(true)}
               >
                 <div className="">
                   <MdApproval />
                 </div>
                 <div className="ml-1">Approve</div>
               </button>
             ) : null}

             {isEditable ? null : (
               <ReactToPrint
                 trigger={() => {
                   return (
                     <button className="flex justify-center border p-2 items-center focus:outline-none  ml-2 text-sm hover:text-toyota">
                       <AiOutlinePrinter className="mr-1" /> Print
                     </button>
                   );
                 }}
                 content={() => docRef.current}
                 documentTitle={`Claim`}
               />
             )}
           </div>
         }
       >
         <div>
           <div className="flex flex-row bg-whitish overflow-auto">
             <div className="grid grid-cols-12 gap-0 bg-gray-50 w-full">
               <div className="col-span-12 sm:col-span-9">
                 <div className="flex justify-start ml-10">
                   <div className="book" ref={docRef}>
                     <div className="page" ref={docRef}>
                       <div className="subpage">
                         {isLoading ? null : (
                           <DocumentWrapper
                             statements={2}
                             selected={selected}
                             claim={claim}
                             getTotalDue={() => {}}
                             setSelected={setSelected}
                             isEditable={isEditable}
                             updateItemModal={updateItemModal}
                             setUpdateItemModal={setUpdateItemModal}
                             deleteItemModal={deleteItemModal}
                             setDeleteItemModal={setDeleteItemModal}
                           />
                         )}
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
               <div className="col-span-12 sm:col-span-3 bg-white border-l px-6">
                 <div className="flex border-b-2 py-2">
                   <div className="flex items-center justify-center">
                     <div className="pb-1">Submitted:</div>
                     <div className="ml-2">
                       {claim?.isFinished ? (
                         <AiOutlineCheckSquare color="#33cc33" />
                       ) : (
                         <AiOutlineCloseSquare color="#E50000" />
                       )}
                     </div>
                   </div>

                   <div className="flex items-center ml-4">
                     <div className="pb-1">Approval:</div>
                     <div className="ml-2">
                       {claim?.status === "Approved" ? (
                         <AiOutlineCheckSquare color="#33cc33" />
                       ) : null}
                       {claim?.status === "Declined" ? (
                         <AiOutlineCloseSquare color="#E50000" />
                       ) : null}
                     </div>
                   </div>
                 </div>
                 <div className=" border-b py-4">Attachments : </div>

                 <div className="">
                   {attachments?.length === 0 ? (
                     <div className="mt-12">
                       <NoResults title={"No Attachments"} />
                     </div>
                   ) : null}
                   {attachments?.map((item: any, index: number) => (
                     <div className="flex justify-between mt-2" key={index}>
                       <div className="flex">
                         <div className="mr-2">
                           <img
                             src={
                               item.filetype === "application/pdf"
                                 ? pdf_avatar
                                 : pht_avatar
                             }
                             alt="Logo"
                             height={15}
                             width={35}
                           />
                         </div>
                         <div className="flex flex-col justify-center">
                           <div className="">{item?.category}</div>
                           <div className="flex items-center text-xs">
                             <span className="w-16">
                               {formatSize(item?.filesize)}
                             </span>
                             <button
                               onClick={() => {
                                 setSelectedItem(item);
                                 setItemFileModal(true);
                               }}
                               className="flex items-center"
                             >
                               <GrUpload size={10} />{" "}
                               <span className="ml-1">Re-Upload</span>
                             </button>
                           </div>
                         </div>
                       </div>
                       <div className="flex justify-center items-center">
                         {item?.filetype === "application/pdf" ? (
                           <a
                             href={`${item?.attachment}`}
                             target="_blank"
                             className="ml-4 hover:text-green-600 text-black"
                           >
                             <BsDownload />
                           </a>
                         ) : (
                           <>
                             {preview?._id === item?._id ? (
                               <button
                                 onClick={() => setPreview("")}
                                 className=""
                               >
                                 <BsEyeFill color="#16a34a" />
                               </button>
                             ) : (
                               <button
                                 onClick={() => setPreview(item)}
                                 className=""
                               >
                                 <BsEye />
                               </button>
                             )}
                             <a
                               href={item?.attachment}
                               target="_blank"
                               download={true}
                               className="ml-2 text-black"
                             >
                               <button>
                                 <BsDownload />
                               </button>
                             </a>
                           </>
                         )}
                       </div>
                     </div>
                   ))}
                   {preview ? (
                     <div className="border p-1 mt-4">
                       {preview?.filetype === "application/pdf" ? (
                         <div className="">
                           <a href={`${preview?.attachment}`} target="_black">
                             Pdf
                           </a>
                         </div>
                       ) : (
                         <img src={`${preview?.attachment}`} />
                       )}
                     </div>
                   ) : null}
                 </div>
               </div>
             </div>
           </div>
         </div>
       </Wrapper>

       <CreateItem
         isVisible={createModal}
         setVisible={setCreateModal}
         selected={claim}
         refetch={handleReFetchClaim}
       />

       <DeleteItem
         isVisible={deleteItemModal}
         setVisible={setDeleteItemModal}
         selected={selected}
         claim={claim}
         refetch={handleReFetchClaim}
       />

       <UpdateItem
         isVisible={updateItemModal}
         setVisible={setUpdateItemModal}
         selected={selected}
         claim={claim}
         refetch={handleFetchClaim}
       />

       <Document
         isVisible={documentModal}
         setVisible={setDocumentModal}
         selected={selected}
         claim={claim}
         refetch={handleReFetchClaim}
       />

       <ApproveClaim
         isVisible={approveModal}
         setVisible={setApproveModal}
         selected={selected}
         claim={claim}
         refetch={handleReFetchClaim}
         total={total}
       />

       <SubmitClaim
         isVisible={submitModal}
         setVisible={setSubmitModal}
         selected={selected}
         claim={claim}
         refetch={handleReFetchClaim}
         total={total}
       />

       <DeleteClaim
         isVisible={deleteModal}
         setVisible={setDeleteModal}
         selected={claim}
         claim={claim}
         refetch={handleReFetchClaim}
       />

       <UploadFile
         isVisible={itemFileModal}
         setVisible={setItemFileModal}
         selectedItem={selectedItem}
         claim={claim}
         refetch={handleReFetchClaim}
       />
     </>
   );
}
