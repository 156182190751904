import { useEffect, useState } from "react";
import formatMoney from "../../../utils/formatMoney";
import claimsByCateg from "../../../utils/claimsByCateg";
import ClaimsPanel from "./ClaimsPanel";

export default function CategWidget({ claims, constants }: any) {
  const [data, setData] = useState<any>();
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const handleFormatData = async () => {
    const data = await claimsByCateg({ claims });
    setData(data);
  };

  useEffect(() => {
    handleFormatData();
  }, [claims]);
  return (
    <div>
      {data?.data?.map((item: any, index: number) => (
        <div
          className="border p-2 bg-white mb-1 hover:bg-blue-50 hover:cursor-pointer"
          key={index}
        >
          <button
            className="flex justify-between items-center w-full "
            onClick={() => {
              setSelected(item);
              setVisible(true);
            }}
          >
            <div className="text-gray-600">{item?.category}</div>
            <div className="">
              {item?.total ? formatMoney(item?.total ? item?.total : 0) : "-"}
            </div>
          </button>
        </div>
      ))}

      <div className="border-t-2 border-black p-2 bg-white mb-1">
        <div className="flex justify-between items-center">
          <div className="">Total</div>
          <div className="">
            {data?.total ? formatMoney(data?.total ? data?.total : 0) : "-"}
          </div>
        </div>
      </div>

      <ClaimsPanel
        isVisible={isVisible}
        setVisible={setVisible}
        selected={selected}
      />
    </div>
  );
}
