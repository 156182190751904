const claimStatus: any = {
  // New: "#eff6ff",
  // Approved: "#f0fdf4",
  // Forwarded: "#fffbeb",
  // Declined: "#fef2f2",
  New: "#FFFFFF",
  Approved: "#BCEBD1",
  Forwarded: "#F3D800",
  Declined: "#FAC6D4",
};

const claimColors: any = {
  New: "bg-white hover:cursor-pointer hover:bg-gray-100",
  Approved: "bg-green-200 hover:cursor-pointer hover:bg-gray-100",
  Forwarded: "bg-yellow-200 hover:cursor-pointer hover:bg-gray-100",
  Declined: "bg-red-200 hover:cursor-pointer hover:bg-gray-100",
};

const months: any = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",

  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
};

export { claimStatus, claimColors, months };
