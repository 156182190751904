import { CLAIM_BASE_URL } from "../utils/urls";

const patchDataClaim = async (data: any, uri: any) => {
  const response = await fetch(`${CLAIM_BASE_URL}/${uri}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const result = await response.json();
  return result;
};

export default patchDataClaim;
