import { useEffect, useState } from "react";
import { FiChevronsRight } from "react-icons/fi";
import { months } from "../../../utils/data";
import claimsByMonth from "../../../utils/claimsByMonth";
import formatMoney from "../../../utils/formatMoney";
import MainPanel from "./MainPanel";
import Spinner from "./Spinner";

export default function MonthWidget({ claims, isLoading, year }: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  const thismonth = new Date().getMonth() + 1;
  const [data, setData] = useState<any>({});

  const handleFetchMonthData = async () => {
    const month = months[thismonth];
    const filtered = await claimsByMonth({ claims, month });
    setData(filtered);
  };

  useEffect(() => {
    handleFetchMonthData();
  }, [claims]);

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col flex-1 p-4">
        <div className="text-gray-400 text-xs uppercase">
          {months[thismonth]} EXPENSES : {year}
        </div>
        <div className="font-bold text-toyota text-2xl">
          {isLoading ? <Spinner /> : formatMoney(data?.total ? data?.total : 0)}
        </div>
      </div>
      <button
        onClick={() => {
          setVisible(true);
        }}
        className="flex justify-between items-center border-t h-10 p-4 hover:bg-blue-50 hover:cursor-pointer"
      >
        <div className="mb-2">See Details</div>
        <div className="">
          <FiChevronsRight />
        </div>
      </button>

      <MainPanel
        isVisible={isVisible}
        setVisible={setVisible}
        selected={data}
      />
    </div>
  );
}
