import { useContext, useEffect, useState } from "react";

import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import patchDataTrace from "../../../services/patchDataTrace";
import { AuthContext } from "../../../contexts/AuthContext";
export default function UpdateDetails({ isVisible, setVisible }: any) {
  const { user, setUser } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);
  const { _id, firstname, lastname, email, mobile } = user;

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const values = await form.validateFields();
    const uri = `users/${_id}`;
    const response = await patchDataTrace(values, uri);
    if (response.success) {
      message.success("Updated successfully");
      setUser(response.payload);
      setVisible(false);
    } else {
      message.error("Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, user]);

  return (
    <Modal
      visible={isVisible}
      title={<div className="flex">Update Details</div>}
      onCancel={() => setVisible(!isVisible)}
      width={680}
      footer={[
        <Button key="back" onClick={() => setVisible(!isVisible)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updDetails"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updDetails"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="firstname"
              initialValue={firstname}
              rules={[
                {
                  required: true,
                  message: "Please enter firstname",
                },
              ]}
            >
              <Input placeholder="First Name" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="lastname"
              initialValue={lastname}
              rules={[
                {
                  required: true,
                  message: "Please enter lastname",
                },
              ]}
            >
              <Input placeholder="Last Name" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              initialValue={email}
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
              ]}
            >
              <Input
                type={"email"}
                placeholder="Email"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              initialValue={mobile}
              rules={[
                {
                  required: true,
                  message: "Please enter mobile",
                },
              ]}
            >
              <Input placeholder="Mobile" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
