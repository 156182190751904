import { Button, Modal, Form, Row, Col, Select, Input } from "antd";
import { useEffect, useState } from "react";
import SweetAlert2 from "react-sweetalert2";
import { BsUpload } from "react-icons/bs";
import getBase64 from "../../../utils/getBase64";
import formatMoney from "../../../utils/formatMoney";
import patchDataClaim from "../../../services/patchDataClaim";

const { Option } = Select;

export default function UploadFile({
  isVisible,
  selectedItem,
  claim,
  setVisible,
  refetch,
}: any) {
  const [isLoading, setLoading] = useState(false);
  const [receipt, setReceipt] = useState<any>(null);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [swalProps, setSwalProps] = useState({});

  const [form] = Form.useForm();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const onSubmit = async () => {
    setLoading(true);

    let base64,
      type,
      size = null;

    if (selectedFile) {
      base64 = await getBase64(selectedFile);
      type = selectedFile?.type?.slice(selectedFile?.type - 3);
      size = selectedFile?.size;
    }

    const data = {
      category: selectedItem?.category,
      hasAttachment: false,
      attachment: base64,
      filetype: type,
      filesize: size,
      hasReceipt: selectedFile ? true : false,
    };

    const uri = `claim/${claim?._id}/item/${selectedItem?._id}/upload`;
    const response = await patchDataClaim(data, uri);

    if (response.success) {
      setSwalProps({
        show: true,
        title: "Success",
        text: "Uploaded Successfully",
        icon: "success",
      });
      await refetch();
      form.resetFields();
      hideModal();
      setIsSelected(false);
      setSelectedFile(null);
      setLoading(false);
      setSwalProps({});
    } else {
      setSwalProps({
        show: true,
        title: "Error",
        text: "Sorry, Something went wrong",
        icon: "error",
      });
      hideModal();
      setLoading(false);
      setIsSelected(false);
      setSelectedFile(null);
      setSwalProps({});
    }
    setLoading(false);
  };

  const hideModal = () => {
    setVisible(false);
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <Modal
      open={isVisible}
      title="Upload Attachment"
      onCancel={hideModal}
      footer={[
        <Button key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="uploadItemAttachment"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Add
        </Button>,
      ]}
    >
      <Form
        id="uploadItemAttachment"
        form={form}
        layout="vertical"
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
      >
        <div className="mb-4">
          File For : <span>{selectedItem?.category}</span>{" "}
          <span>{formatMoney(selectedItem?.amount)}</span>
        </div>
        <div className="flex flex-col justify-center items-center bg-gray-100 h-32 rounded-md border">
          {receipt ? (
            <div className="flex flex-col justify-center items-center">
              <div className="mb-1">
                <BsUpload size={20} />
              </div>
              <div className="mt-1">Upload supporting document (if any)</div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <Form.Item
                name="file"
                label="Upload supporting document ( Optional )"
                rules={[
                  {
                    required: false,
                    message: "Please select file",
                  },
                ]}
              >
                <Input
                  type="file"
                  // accept="image/png, image/gif, image/jpeg"
                  placeholder="Document"
                  onChange={changeHandler}
                />
              </Form.Item>
            </div>
          )}
        </div>
      </Form>
      <SweetAlert2 {...swalProps} />
    </Modal>
  );
}
