import { slice } from "lodash";
import { BsTrash } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import DocumentEdit from "./DocumentEdit";

export default function DocumentEdits({
  items,
  selected,
  setSelected,
  updateItemModal,
  setUpdateItemModal,
  deleteItemModal,
  setDeleteItemModal,
}: any) {
  const data = new Array(22).fill({ id: 1 });
  let few = slice(data, 0, 22 - items?.length);
  return (
    <div className="border border-black  mt-2">
      <div className="grid grid-cols-12 border-b  border-black  bg-gray-200">
        <div className="col-span-3 p-1 font-bold border-r border-black  text-left">
          Description
        </div>
        <div className="col-span-3 p-1 font-bold border-r border-black  text-left">
          Claim Type
        </div>
        <div className="col-span-2 p-1 font-bold border-r border-black  text-center">
          Receipt
        </div>
        <div className="col-span-2 p-1 font-bold border-r border-black  text-right">
          Amount
        </div>
        <div className="col-span-1 p-1 font-bold border-r border-black  text-center">
          <button className="cursor-pointer">
            <FiEdit size={16} color="#eab308" />
          </button>
        </div>
        <div className="col-span-1 p-1 font-bold text-center">
          <button
            // onClick={() => {
            //   setSelected({
            //     ...record,
            //   });
            //   setUpdateModal(true);
            // }}
            className="cursor-pointer"
          >
            <BsTrash size={16} color="red" />
          </button>
        </div>
      </div>
      <div className="">
        {items?.map((item: any, i: any) => (
          <DocumentEdit
            style={{ color: "red" }}
            key={i + item._id}
            index={i}
            item={item}
            selected={selected}
            setSelected={setSelected}
            updateItemModal={updateItemModal}
            setUpdateItemModal={setUpdateItemModal}
            deleteItemModal={deleteItemModal}
            setDeleteItemModal={setDeleteItemModal}
          />
        ))}
      </div>
      {few.map((item: any, index: number) => (
        <div
          className="grid grid-cols-12 items-center border-t border-black"
          key={index}
        >
          <div className="col-span-3 px-1 border-r text-white  border-black  text-center">
            -
          </div>
          <div className="col-span-3 px-1 text-white border-r border-black text-right">
            -
          </div>
          <div className="col-span-2 px-1 text-white border-r border-black text-right">
            -
          </div>
          <div className="col-span-2 px-1 text-white border-r border-black  text-right">
            -
          </div>
          <div className="col-span-1 px-1 text-white border-r border-black  text-right">
            -
          </div>
          <div className="col-span-1 px-1 text-white text-right">-</div>
        </div>
      ))}
    </div>
  );
}
